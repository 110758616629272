export enum WorkspaceVerticals {
	HUMANITARIAN = 'humanitarian',
	HIGHER_EDUCATION = 'higher-education',
	SOCIAL_SERVICES = 'social-services',
	ANIMALS = 'animals',
	FAITH = 'faith',
	ENVIRONMENTAL = 'environmental',
	ASSOCIATIONS = 'associations',
	POLITICAL = 'political',
	K12 = 'k12',
	ARTS = 'arts',
	OTHER = 'other',
	EDUCATION = 'education',
	TRAVEL = 'travel',
	RETAIL = 'retail',
}

export const workspaceVerticalOptions = [
	{
		value: WorkspaceVerticals.EDUCATION,
		label: 'Education',
	},
	{
		value: WorkspaceVerticals.TRAVEL,
		label: 'Travel',
	},
	{
		value: WorkspaceVerticals.RETAIL,
		label: 'Retail',
	},
	{
		value: WorkspaceVerticals.OTHER,
		label: 'Other',
	},
];

export enum SettingsAccordionEnum {
	PERSONAL = 'PERSONAL',
	WORKSPACE = 'WORKSPACE',
	TEMPLATES = 'TEMPLATES',
}

export enum SettingsPageName {
	// Personal settings
	profile = 'Profile',
	notifications = 'Notifications',

	// Workspace settings
	general = 'General',
	videoPage = 'Default video page',
	captions = 'Captions',
	trust = 'Trust',
	sending = 'Sending',
	dataImport = 'Data import',
	dataExport = 'Data export',
	contactExport = 'Contact export',
	customAttributes = 'Custom attributes',
	unsubscribers = 'Unsubscribers',
	optOuts = 'Opt-outs',
	textingTemplates = 'Texting templates',
	videoMessagingTemplates = 'Video messaging templates',
	postcardTemplates = 'Postcard templates',

	// Old settings
	settingsMenu = 'Settings',
	videoPageEditor = 'Video page',
	myTeam = 'Team',
	integrations = 'Contact importing',
	workspaceInfo = 'Workspace',
	unsubscribes = 'Unsubscribes',
	smsEmailTemplates = 'SMS & Email templates',
	blackbaud = 'Blackbaud',
	microsoftDynamics = 'Microsoft Dynamics',
	salesforce = 'Salesforce',
	zapier = 'Zapier',
	termsConditions = 'Terms & Conditions',
	automations = 'Data exporting',
	deliveryChannels = 'Channels',
	teams = 'Teams',
}

export enum SettingsRoute {
	captions = 'captions',
	trust = 'trust',
	customAttributes = 'custom-attributes',
	dataImport = 'data-import',
	dataExport = 'data-export',
	contactExport = 'contact-export',
	notifications = 'notifications',
	general = 'general',
	profile = 'profile',
	sending = 'sending',
	unsubscribes = 'unsubscribes',
	videoPage = 'video-page',

	templates = 'templates',
	termsConditions = 'terms-conditions',
	automations = 'automations',
	deliveryChannels = 'delivery-channels',
	videoMessagingTemplates = 'video-messaging-templates',
	textingTemplates = 'texting-templates',
	postcardTemplates = 'postcard-templates',
}

export const BorderOptions = [
	{
		value: true,
		label: 'Rounded',
	},
	{
		value: false,
		label: 'Sharp',
	},
];

export const OrganizationPlans = [
	{
		title: 'Video messaging',
		text: 'video-messaging',
		activeIcon: 'videomessagingplan',
		inactiveIcon: 'videomessagingplaninactive',
	},
	{
		title: 'Chatbot',
		text: 'chatbot',
		activeIcon: 'chatbotplan',
		inactiveIcon: 'chatbotplaninactive',
	},
	{
		title: 'Reels',
		text: 'reels',
		activeIcon: 'reelsplan',
		inactiveIcon: 'reelsplaninactive',
	},
	{
		title: 'SMS',
		text: 'sms',
		activeIcon: 'smsplan',
		inactiveIcon: 'smsplaninactive',
	},
];

export enum AutoCorrectionTabsEnum {
	SUGGESTIONS = 'suggestions',
	OVERRIDES = 'overrides',
}

export enum CustomAttributeTabsEnum {
	CUSTOMATTRIBUTES = 'Custom attributes',
	MAPPEDFIELDS = 'Mapped fields',
}

export const customAttirbutesOptions = [
	{
		value: 'text',
		label: 'Text',
		icon: 'text-a-underline',
	},
	{
		value: 'number',
		label: 'Number',
		icon: 'hash',
	},
	{
		value: 'boolean',
		label: 'Boolean',
		icon: 'subtract',
	},
	{
		value: 'date',
		label: 'Date',
		icon: 'calendar',
	},
];
export const WorksapceRoleTooltipInfo = {
	admin:
		'Workspace admins have full access to the Goodkind platform including user management and all settings.',
	recorder:
		'Recorders have limited access to the Goodkind web application and full access to the Goodkind mobile application.',
};

export enum HotglueFlowTypes {
	contactImport = 'contactImport',
	roiTracking = 'roiTracking',
	sendDataOut = 'sendDataOut',
	contactExport = 'contactExport',
}
