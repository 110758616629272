import { operators, orderTypes } from '@app/constants';
import { filterObjectArray } from '@app/types';
import { env } from '@app/env';
import { CTA } from '@app/redux/cta/types';
import { videoPageMessageType } from '../../types';
import {
	VideoStatusEnum,
	reelsVideoUrls,
	videoUrls,
} from '../../containers/VideoPage/constant';
import { VideoSaveStoreType } from '../../../Video/saveStore';
import { WorkspaceType } from '@app/redux/workspace/types';
import { StorytellerType } from '@app/redux/storyteller/types';

const isPreviewScreen = (screenType: VideoStatusEnum) =>
	screenType === VideoStatusEnum.preview;
const isPlayScreen = (screenType: VideoStatusEnum) =>
	screenType === VideoStatusEnum.play;
const isEndScreen = (screenType: VideoStatusEnum) =>
	screenType === VideoStatusEnum.end;

const getCallToActionFilter = (page = 1) => {
	const pagination = {
		page,
		pageSize: 20,
	};

	const order = { field: 'createdAt', type: orderTypes.descending };

	const filter: filterObjectArray = [
		{ field: 'preview', value: true, operator: operators.not },
	];

	return {
		pagination,
		order,
		filter,
	};
};

/**
 * Returns the url with the edit param added as a route
 * @param {string} url with format url.com/videoId
 * @returns {string} urlReplaced with format url.com/edit/videoId
 */
const addEditParamAsRouteOnUrl = (url: string): string => {
	const split = url.split('');

	const indexToReplace = split.lastIndexOf('/');

	split.splice(indexToReplace, 0, '/edit');

	const urlReplaced = split.join('') || '';

	return urlReplaced || url;
};

const getVideoId = (
	video:
		| string
		| {
				id?: string;
				_id?: string;
		  },
) => {
	if (typeof video === 'string') return video;

	if (typeof video === 'object') return video?.id || video?._id;

	return null;
};

const getVideoSrc = ({
	video,
	isReels,
}: {
	video:
		| string
		| {
				id?: string;
				_id?: string;
		  };
	isReels?: boolean;
}) => {
	const currentEnv = env.NODE_ENV || 'development';

	const videoId = getVideoId(video);

	const isProd = ['production', 'prod'].includes(currentEnv);

	const isQa = ['qa'].includes(currentEnv);

	const isDev = ['development', 'dev'].includes(currentEnv);

	if (isProd) {
		const baseUrl = isReels ? reelsVideoUrls.PROD : videoUrls.PROD;
		return `${baseUrl}${videoId ? `?v=${videoId}` : ''}`;
	}

	if (isQa) {
		const baseUrl = isReels ? reelsVideoUrls.QA : videoUrls.QA;
		return `${baseUrl}${videoId ? `?v=${videoId}` : ''}`;
	}

	if (isDev) {
		const baseUrl = isReels ? reelsVideoUrls.DEV : videoUrls.DEV;
		return `${baseUrl}${videoId ? `?v=${videoId}` : ''}`;
	}

	const baseUrl = isReels ? reelsVideoUrls.LOCAL : videoUrls.LOCAL;
	return `${baseUrl}${videoId ? `?v=${videoId}` : ''}`;
};

const getVideoPageLivePreviewFields = ({
	callToActions,
	templateFields,
	campaignSettings,
	screen,
	suggestedReplies,
	campaign,
	workspace,
	stories,
	storytellers,
	isSnapchatBackgroundOn,
}: {
	templateFields: any;
	callToActions: CTA[];
	screen: VideoStatusEnum;
	campaignSettings: any;
	suggestedReplies: CTA[];
	campaign: any;
	workspace: WorkspaceType;
	stories: VideoSaveStoreType['stories'];
	storytellers: StorytellerType[];
	isSnapchatBackgroundOn: boolean; //TODO: Remove this prop when the feature toggle is removed
}): videoPageMessageType => {
	return {
		personalization: campaign?.personalization,
		buttonSize: templateFields.buttonSize,
		reelsTitle: campaign?.reelsTitle,
		hideRecordersName: !!campaignSettings?.hideRecordersName,
		headerText: templateFields.headerText,
		bodyText: templateFields.bodyText,
		playButtonText: templateFields.playButtonText,
		endHeaderText: templateFields.endHeaderText,
		videoStatus: screen, //handles preview - play - end screen
		primaryColor: templateFields.primaryColor,
		secondaryColor: templateFields.secondaryColor,
		backgroundImage: templateFields.backgroundImage,
		thumbnailImage: templateFields.thumbnailImage,
		snapchatBackgroundImage:
			isSnapchatBackgroundOn && templateFields.snapchatBackgroundImage,
		logo: templateFields.logo,
		organizationName: workspace.organizationName,
		round: templateFields.borderRadius,
		replies: suggestedReplies,
		autoplay: !!campaignSettings?.autoplay,
		isReplyActive: !!campaignSettings?.replies,
		callToActions: callToActions,
		automaticRedirect: !!campaignSettings?.automaticRedirect,
		automaticRedirectThumbnail:
			campaignSettings?.automaticRedirectThumbnail ?? '',
		creationMethod: campaign?.creationMethod,
		captionWarning: !!campaignSettings?.captionWarning,
		stories,
		storytellers,
	};
};

export {
	isEndScreen,
	isPlayScreen,
	isPreviewScreen,
	getCallToActionFilter,
	addEditParamAsRouteOnUrl,
	getVideoSrc,
	getVideoPageLivePreviewFields,
};
