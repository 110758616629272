import React, { useRef, useState } from 'react';

import TableRow from './components/TableRow';

import { TableProps, TableMapperType } from './types';

import {
	Checkbox,
	DisplayIcon,
	Icon,
	Loader,
	Text,
	Tooltip,
} from '@common/design-system/components/atoms';
import { InfiniteScroller } from '@common/design-system/components/layout';

import * as S from './Table.styles';
import { SystemMessage } from '../../molecules';

function Table({
	id,
	tableMappers,
	tableData,
	selectedIds,
	loading,
	className,
	onClickRow,
	onCheckRow,
	onCheckAll,
	cellSize = 'medium',
	headerSize = 'medium',
	headerTheme = 'light',
	withBorder = true,
	infiniteScrollerProps,
	firstColumnFixed = false,
	lastColumnFixed = false,
	emptyStateSystemMessageProps,
	isAccordion = false,
	tableHeaderProps,
	tableHeaderElement,
}: TableProps) {
	const tableCount = Object.keys(tableData)?.length || 0;
	const isAllChecked = tableCount > 0 && selectedIds?.length === tableCount;

	const cellSizeMap = {
		small: 48,
		medium: 56,
		large: 64,
		xl: 80,
		xxl: 96,
	};

	const headerSizeMap = {
		small: 40,
		medium: 48,
	};

	const tableHeaderContainerRef = useRef<HTMLDivElement>(null);
	const tableHeaderContainerHeight =
		tableHeaderContainerRef?.current?.offsetHeight || 0;

	const [isTableAccordionOpen, setIsTableAccordionOpen] = useState(true);

	//Generate table random id, save in reference
	const generateTableId = () => {
		return Math.random().toString(36).slice(2);
	};

	const tableId = useRef(generateTableId());

	const TableComponent = () => {
		return (
			<S.StyledTable>
				<S.StyledThead headerTheme={headerTheme}>
					<S.HeadingRow
						heightSize={headerSizeMap[headerSize]}
						firstColumnFixed={firstColumnFixed}
						lastColumnFixed={lastColumnFixed}
					>
						{tableMappers.map((heading: TableMapperType, index: number) => (
							<S.TableHeader
								key={`heading-${index}`}
								centered={heading?.centered}
								heightSize={headerSizeMap[headerSize]}
								headerTheme={headerTheme}
							>
								{index === 0 && (
									<>
										{!!onCheckRow && (
											<S.CheckboxTableCell className="me-3">
												{!!onCheckAll && (
													<Checkbox
														ariaLabel="Select all rows"
														checked={isAllChecked}
														partiallyChecked={
															selectedIds &&
															selectedIds?.length > 0 &&
															!isAllChecked
														}
														disabled={!tableCount}
														onChange={onCheckAll}
													/>
												)}
											</S.CheckboxTableCell>
										)}
									</>
								)}
								{heading.title}
							</S.TableHeader>
						))}
					</S.HeadingRow>
				</S.StyledThead>

				<tbody>
					{!loading && tableCount > 0 && (
						<>
							{Object.entries(tableData)?.map(([id, item]) => (
								<TableRow
									heightSize={cellSizeMap[cellSize]}
									key={id}
									item={item}
									mappers={tableMappers}
									isChecked={selectedIds?.includes(id) || false}
									onClickRow={onClickRow}
									onCheckRow={onCheckRow ? () => onCheckRow(id) : undefined}
									firstColumnFixed={firstColumnFixed}
									lastColumnFixed={lastColumnFixed}
								/>
							))}
						</>
					)}
				</tbody>
			</S.StyledTable>
		);
	};

	return (
		<S.TableContainer
			className={className}
			withBorder={withBorder}
			id={id || tableId.current}
			isTableAccordionOpen={isTableAccordionOpen}
		>
			{(!!tableHeaderProps || !!tableHeaderElement) && (
				<S.TableHeaderContainer
					ref={tableHeaderContainerRef}
					isTableAccordionOpen={isTableAccordionOpen}
				>
					<div className="d-flex align-items-center gap-3">
						{isAccordion && (
							<span
								role="button"
								aria-label="Toggle table open"
								onClick={() => setIsTableAccordionOpen(!isTableAccordionOpen)}
								onKeyDown={() => setIsTableAccordionOpen(!isTableAccordionOpen)}
								tabIndex={0}
								className="d-flex align-items-center cursor-pointer"
							>
								<Icon
									iconName={isTableAccordionOpen ? 'caretUp' : 'caretDown'}
									size="small"
								/>
							</span>
						)}
						{tableHeaderElement}

						{!tableHeaderElement && tableHeaderProps && (
							<S.HeaderTitleContainer className="d-flex align-items-center gap-2">
								<DisplayIcon
									iconName={tableHeaderProps?.iconName}
									iconColor={tableHeaderProps?.iconColor}
									bgColor={tableHeaderProps?.bgColor}
									iconWeight={tableHeaderProps?.iconWeight || 'fill'}
								/>
								{!loading && (
									<>
										<Text lineHeight="large" fontWeight="medium">
											{tableHeaderProps?.title}
										</Text>

										{!!tableHeaderProps?.tooltipInfo && (
											<Tooltip content={tableHeaderProps?.tooltipInfo}>
												<Icon iconName="info" color="system.text.weak" />
											</Tooltip>
										)}

										{!!tableHeaderProps?.count && (
											<Text
												lineHeight="large"
												color="system.text.weak"
												fontWeight="medium"
											>
												{`(${tableHeaderProps?.count})`}
											</Text>
										)}
									</>
								)}
							</S.HeaderTitleContainer>
						)}

						{loading && <Loader size="xs" />}
					</div>
				</S.TableHeaderContainer>
			)}

			{isTableAccordionOpen && (
				<>
					{!!infiniteScrollerProps && infiniteScrollerProps?.next && (
						<InfiniteScroller
							style={{
								height: `calc(100% - ${tableHeaderContainerHeight}px)`,
								width: '100%',
								position: 'absolute',
							}}
							className={`${id || tableId.current}-infinite-scroll`}
							scrollableTarget={`${id || tableId.current}-infinite-scroll`}
							next={infiniteScrollerProps?.next}
							dataLength={infiniteScrollerProps?.dataLength || 0}
							hasMore={infiniteScrollerProps?.hasMore ?? false}
							loader={
								<div className="d-flex justify-content-center align-items-center">
									<Loader loading size="small" />
								</div>
							}
							hasChildren={true}
						>
							<TableComponent />
						</InfiniteScroller>
					)}

					{!infiniteScrollerProps && <TableComponent />}

					{loading && (
						<S.TableCenterContainer>
							<Loader size="small" />
						</S.TableCenterContainer>
					)}

					{!loading && tableCount <= 0 && (
						<S.TableCenterContainer>
							<SystemMessage
								title={
									emptyStateSystemMessageProps?.title || 'No results found'
								}
								iconName={emptyStateSystemMessageProps?.iconName || 'search'}
								alt={emptyStateSystemMessageProps?.alt || 'No results found'}
								description={
									emptyStateSystemMessageProps?.description ||
									"It's seems there's no match for your search or filters"
								}
							/>
						</S.TableCenterContainer>
					)}
				</>
			)}
		</S.TableContainer>
	);
}

export default React.memo(Table);
