import React, { useEffect, useMemo, useRef, useState } from 'react';
import { RootState } from '@app/redux/types';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';

import { useFeature } from '@growthbook/growthbook-react';
import { growthbookFeatures } from '@app/constants/growthbook';
import { useTemplatesContext } from '../../containers/BuildStep/VideoPageTemplatesProvider';
import { useBuilderCTAContext } from '../../containers/BuildStep/providers/BuilderCTAProvider';
import { useBuilderRepliesContext } from '../../containers/BuildStep/providers/BuilderRepliesProvider';

import LiveEditPreview from '../LiveEditPreview/LiveEditPreview';

import { getPreviewContainerNode } from '../../containers/BuildStep/utils';
import { VideoStatusEnum } from '../../containers/BuildStep/containers/VideoPage/constant';
import {
	getVideoPageLivePreviewFields,
	getVideoSrc,
} from '../../containers/BuildStep/containers/VideoPage/utils';
import useVideoSaveStore, {
	VideoSaveStoreType,
} from '../../containers/Video/saveStore';
import {
	checkIsOneToManyAssignNewVideo,
	checkIsOneToManyVideoFromLibrary,
	checkIsOneToOne,
	checkIsReels,
} from '@app/utils/modules/campaigns';
import useBuildSaveStore, {
	BuildSaveStoreType,
} from '../../containers/BuildStep/saveStore';
import styled from 'styled-components';

type LiveVideoPagePreviewProps = {
	isGkPlayer?: boolean;
	videoPageSettingsMessage?: { [key: string]: any };
};

const LiveEditPreviewContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
	padding: ${({ theme }) => theme.spacing.small};
`;

export default function LiveVideoPagePreview({
	videoPageSettingsMessage = {},
}: LiveVideoPagePreviewProps) {
	const videoSaveStore = useVideoSaveStore() as VideoSaveStoreType;

	const { storedTemplateFields } = useTemplatesContext();

	const { storedCTAFields } = useBuilderCTAContext();

	const buildSaveStore = useBuildSaveStore() as BuildSaveStoreType;

	const storeCampaignSettings = buildSaveStore?.campaign?.settings;

	const { storedRepliesFields } = useBuilderRepliesContext();

	const isSnapchatBackgroundOn = useFeature(
		growthbookFeatures.FRONTEND_SNAPCHAT_BACKGROUND,
	).on;

	/** Redux states */
	const workspace = useSelector(
		({ workspace }: RootState) => workspace?.workspaceInfo,
	);

	const campaign = useSelector(
		({ campaign }: RootState) => campaign?.savedCampaign,
	);
	const campaignVideo = campaign?.video;

	const isReelsCampaign = checkIsReels(campaign?.personalization);
	const isOneToOne = checkIsOneToOne(campaign);
	const isOneToManyNA = checkIsOneToManyAssignNewVideo(campaign);
	const isOneToManyVFL = checkIsOneToManyVideoFromLibrary(campaign);

	/** screen handler for the iframe in the content tab **/
	const [screenType, setScreenType] = useState<VideoStatusEnum>(
		VideoStatusEnum.preview,
	);

	/** Fields logic **/
	const baseFields = useMemo(
		() => storedTemplateFields,
		[storedTemplateFields],
	);

	/** Campaign settings fields*/
	const campaignSettingsFields = useMemo(
		() => storeCampaignSettings,
		[storeCampaignSettings],
	);

	/** Suggested replies fields **/
	const repliesFields = useMemo(
		() => storedRepliesFields,
		[storedRepliesFields],
	);

	/** CTA logic */
	const callToActionFields = useMemo(() => storedCTAFields, [storedCTAFields]);

	/** I frame logic **/
	const iframeRef = useRef<
		HTMLIFrameElement & { contentWindow: Window | null }
	>(null);

	const [isInitialLoad, setIsInitialLoad] = useState(true);

	/** Video iframe states **/
	const [isVideoPageIframeLoading, setIsVideoPageIframeLoading] =
		useState(true);

	// Storytellers - to support editmode with storyteller(s)
	const storytellers = useMemo(() => {
		if (isReelsCampaign) return [];

		if (isOneToOne || isOneToManyNA || isOneToManyVFL) {
			return campaign?.storytellers;
		}
	}, [isOneToOne, isOneToManyNA, isOneToManyVFL, isReelsCampaign, campaign]);
	// Stories
	const stories = useMemo(() => {
		if (!isReelsCampaign) return;

		return videoSaveStore?.stories?.length > 0
			? videoSaveStore?.stories
			: campaign?.stories;
	}, [isReelsCampaign, campaign, videoSaveStore]);

	/** Iframe event logic **/
	const sendToFrame = () => {
		if (!iframeRef || !iframeRef.current?.contentWindow) return;

		if (Object.keys(videoPageSettingsMessage).length > 0) {
			iframeRef.current.contentWindow.postMessage(
				{
					...videoPageSettingsMessage,
					videoStatus: screenType,
				},
				'*',
			);
			return;
		}

		const videoPageMessage = getVideoPageLivePreviewFields({
			suggestedReplies: !!repliesFields ? [repliesFields] : [],
			campaignSettings: campaignSettingsFields,
			templateFields: baseFields,
			screen: screenType,
			callToActions: !!callToActionFields ? [callToActionFields] : [],
			campaign: campaign,
			workspace,
			stories,
			storytellers,
			isSnapchatBackgroundOn,
		});

		iframeRef.current.contentWindow.postMessage(videoPageMessage, '*');
	};

	// Feeback handling from the iframe logic
	const handleFeedbackFromVideoPage = (e: MessageEvent) => {
		const feedbackSource = e?.data?.source;
		const videoSrc = getVideoSrc({
			video: campaignVideo,
			isReels: isReelsCampaign,
		});
		const isATrustSource = feedbackSource?.includes(videoSrc);

		if (!isATrustSource) return;

		const videoStatus = e?.data?.videoStatus;
		if (!!videoStatus && Object.values(VideoStatusEnum).includes(videoStatus)) {
			setScreenType(e?.data?.videoStatus);
		}

		if (
			typeof e?.data?.readiness === 'boolean' &&
			e?.data?.readiness === true
		) {
			sendToFrame();
		}
	};

	useEffect(() => {
		window.addEventListener(
			'message',
			(e) => handleFeedbackFromVideoPage(e),
			false,
		);

		return () => {
			window.removeEventListener(
				'message',
				(e) => handleFeedbackFromVideoPage(e),
				false,
			);
		};
	}, []);

	useEffect(() => {
		// Only dispatch if it's using external fields
		if (Object.keys(videoPageSettingsMessage || {})?.length > 0) {
			sendToFrame();
		}
		/** Dependencies
		 *
		 * videoPageSettingsMessage - to send the message to the iframe
		 * ***/
	}, [videoPageSettingsMessage]);

	useEffect(() => {
		sendToFrame();
		/** Dependencies
		 *
		 * previewFields - to update in the iframe the title, intro and button text when it changes
		 * screenType - When changing from preview, play or end
		 * ***/
	}, [
		screenType,
		baseFields,
		campaign?.settings,
		storedRepliesFields,
		storedCTAFields,
		campaignSettingsFields,
		callToActionFields?.length,
	]);
	// SYNCING CHANGES WITH THE SCREEN TYPE
	// => Preview screen
	useEffect(() => {
		if (isInitialLoad) {
			setIsInitialLoad(false);
			return;
		}
		if (screenType !== VideoStatusEnum.preview) {
			setScreenType(VideoStatusEnum.preview);
		}
	}, [baseFields?.headerText, baseFields?.playButtonText]);

	// => Play screen
	useEffect(() => {
		if (isInitialLoad) {
			setIsInitialLoad(false);
			return;
		}
		if (screenType !== VideoStatusEnum.play) {
			setScreenType(VideoStatusEnum.play);
		}
	}, [
		storedCTAFields,
		storedRepliesFields,
		campaignSettingsFields?.replies,
		campaignSettingsFields?.captionEnabled,
	]);

	// => End screen
	useEffect(() => {
		if (isInitialLoad) {
			setIsInitialLoad(false);
			return;
		}
		if (screenType !== VideoStatusEnum.end) {
			setScreenType(VideoStatusEnum.end);
		}
	}, [baseFields?.endHeaderText, campaignSettingsFields?.automaticRedirect]);

	useEffect(() => {
		/** Once the iframe
		 * is mounted this state passes to
		 * false, and it needs to be updated after this
		 * the direct event doesn't do it that's why we use the timeout.
		 * */
		if (isVideoPageIframeLoading === false) {
			setTimeout(() => {
				sendToFrame();
			}, 500);
		}
	}, [isVideoPageIframeLoading]);

	const previewNode = getPreviewContainerNode();

	return (
		<>
			{/** Inside the creation portal you should include the preview component, that can be built
			 * separately in another file or inside this one, and you pass all the props needed to him
			 */}
			{previewNode &&
				createPortal(
					<LiveEditPreviewContainer>
						<LiveEditPreview
							isLoading={isVideoPageIframeLoading}
							screenType={screenType}
							onChangeScreenType={setScreenType}
						>
							<iframe
								title="Summary Video Page Preview"
								loading="lazy"
								onLoad={() => {
									setIsVideoPageIframeLoading(false);
								}}
								src={getVideoSrc({
									video: campaignVideo,
									isReels: isReelsCampaign,
								})}
								ref={iframeRef}
								width="100%"
								height="100%"
								frameBorder="0"
							/>
						</LiveEditPreview>
					</LiveEditPreviewContainer>,
					previewNode,
				)}
		</>
	);
}
