import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl, orderTypes, ToastMessage } from '@app/constants';
import { fetchAction } from '../types';
import axios from '@global/axios';
import { FilterOptions } from '@app/types';

const mainType = 'ACTIVITY';

export const getScheduledActivityTypes = createFetchTypes(
	`${mainType}_GET_SCHEDULED_ACTIVITY`,
);

export const getSentActivityTypes = createFetchTypes(
	`${mainType}_GET_SENT_ACTIVITY`,
);
export const scheduleBulkSendNowTypes = createFetchTypes(
	`${mainType}_SCHEDULE_BULK_SEND_NOW`,
);

export const removeActivityTypes = createFetchTypes(
	`${mainType}_REMOVE_ACTIVITY`,
);
function getScheduledActivity({
	paginationFilter,
	filterByOnlyCampaigns = false,
	filterOptions,
}: {
	paginationFilter: {
		page: number;
		pageSize: number;
	};
	filterByOnlyCampaigns?: boolean;
	filterOptions?: FilterOptions;
}): fetchAction {
	const filterBy: FilterOptions = {
		filter: [...(filterOptions?.filter || [])],
		order: {
			field: 'updatedAt',
			type: orderTypes.descending,
		},
		populate: filterOptions?.populate,
		pagination: paginationFilter ?? { page: 1, pageSize: 25 },
		search: filterOptions?.search,
	};

	return {
		type: getScheduledActivityTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/workspaceActivities/scheduled?filter=${JSON.stringify(filterBy)}&campaignsOnly=${filterByOnlyCampaigns}`,
			),
		payload: {
			custom: 'getScheduledActivity',
		},
	};
}

function getSentActivity({
	paginationFilter,
	filterByOnlyCampaigns = false,
	filterOptions,
}: {
	paginationFilter: {
		page: number;
		pageSize: number;
	};
	filterByOnlyCampaigns?: boolean;
	filterOptions?: FilterOptions;
}): fetchAction {
	const filterBy: FilterOptions = {
		filter: [...(filterOptions?.filter || [])],
		order: {
			field: 'lastMessageSentAt',
			type: orderTypes.descending,
		},
		pagination: paginationFilter ?? { page: 1, pageSize: 25 },
		search: filterOptions?.search,
	};
	return {
		type: getSentActivityTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/workspaceActivities/sent?filter=${JSON.stringify(filterBy)}&campaignsOnly=${filterByOnlyCampaigns}`,
			),
		payload: {
			custom: 'getSentActivity',
		},
	};
}

export function scheduleBulkSendNow(
	selectedActivities: any,
	selectedIds: string[],
): fetchAction {
	return {
		type: scheduleBulkSendNowTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaceActivities/promote`, {
				...selectedActivities,
			}),
		payload: {
			custom: 'scheduleBulkSendNow',
			successMessage: ToastMessage.sendNowActivity,
			selectedIds,
		},
	};
}

export function removeActivity(
	selectedActivities: any,
	selectedIds: string[],
): fetchAction {
	return {
		type: removeActivityTypes,
		callAPI: () =>
			axios.post(`${apiUrl}/workspaceActivities/cancel`, {
				...selectedActivities,
			}),
		payload: {
			custom: 'removeActivity',
			successMessage: ToastMessage.removeActivity,
			selectedIds,
		},
	};
}
export { getScheduledActivity, getSentActivity };
