import createFetchTypes from '@app/utils/createFetchTypes';
import axios from '@global/axios';
import { apiUrl, ToastMessage } from '@app/constants';
import { AudienceDropdownFilter } from '../constants';

import { fetchAction } from '../types';
import { FilterOptions } from '@app/types';
import { AudienceRuleType, AudienceType } from '@app/types/modules/audience';

const mainType = 'AUDIENCE';

export const saveAudienceTypes = createFetchTypes(`${mainType}_SAVE_AUDIENCE`);
export const saveAudienceV2Types = createFetchTypes(
	`${mainType}_SAVE_AUDIENCE_V2`,
);

function saveAudience(
	saveAudienceObject: Partial<AudienceType>,
	custom?: 'uploadAudience',
	showToast = true,
) {
	return {
		type: saveAudienceTypes,
		callAPI: () => axios.post(`${apiUrl}/audience`, saveAudienceObject),
		payload: {
			custom,
			successMessage: showToast && ToastMessage.audienceCreated,
			errorMessage: showToast && ToastMessage.errorCreatingAudience,
		},
	};
}

function saveAudienceV2(
	saveAudienceObject: Partial<AudienceType>,
	custom?: 'uploadAudienceV2',
	showToast = true,
) {
	return {
		type: saveAudienceTypes,
		callAPI: () => axios.post(`${apiUrl}/v2/audience`, saveAudienceObject),
		payload: {
			custom,
			successMessage: showToast && ToastMessage.audienceCreated,
			errorMessage: showToast && ToastMessage.errorCreatingAudience,
		},
	};
}

export const getAudienceTypes = createFetchTypes(`${mainType}_GET_AUDIENCE`);

function getAudience(FilterOptions: FilterOptions) {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: getAudienceTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/audience${FilterOptions ? `?filter=${filterString}` : ``}`,
			),
	};
}

export const getMappedContactListsTypes = createFetchTypes(
	`${mainType}_MAPPED_CONTACT_LISTS`,
);

export function getMappedContactLists(filterOptions: FilterOptions) {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: getMappedContactListsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/audience/v2/mappedLists${
					filterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			custom: 'mappedContactLists',
		},
	};
}

export const updateAudienceTypes = createFetchTypes(
	`${mainType}_UPDATE_AUDIENCE`,
);

type updateAudienceObjectType = {
	id: string;
	name?: string;
	filter?: string;
	[key: string]: any;
};

const defaultUpdateAudienceCustom = 'updateAudience';
function updateAudience(
	updateAudienceObject: updateAudienceObjectType,
	custom = defaultUpdateAudienceCustom,
) {
	return {
		type: updateAudienceTypes,
		callAPI: () => axios.put(`${apiUrl}/audience`, updateAudienceObject),
		payload: {
			custom: custom,
			successMessage:
				custom !== defaultUpdateAudienceCustom && ToastMessage.audienceUpdated,
			errorMessage:
				custom !== defaultUpdateAudienceCustom &&
				ToastMessage.errorUpdatingAudience,
		},
	};
}

export const deleteAudienceTypes = createFetchTypes(
	`${mainType}_DELETE_AUDIENCE`,
);

function deleteAudience(id: string) {
	return {
		type: deleteAudienceTypes,
		callAPI: () => axios.delete(`${apiUrl}/audience/${id}/campaigns`),
		payload: {
			successMessage: ToastMessage.audienceDeleted,
			errorMessage: ToastMessage.error,
			custom: 'deleteAudience',
		},
	};
}

export const getAudienceOptionsTypes = createFetchTypes(
	`${mainType}_GET_OPTIONS`,
);

function getAudienceOptions(): fetchAction {
	return {
		type: getAudienceOptionsTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/audience?filter=${JSON.stringify(AudienceDropdownFilter)}`,
			),
	};
}

export const getSingleAudienceTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_AUDIENCE`,
);

function getSingleAudience(
	audienceId: string,
	custom: string,
	filterOptions?: FilterOptions,
): fetchAction {
	const filterString = JSON.stringify(filterOptions);
	return {
		type: getSingleAudienceTypes,
		callAPI: () =>
			axios.get(`${apiUrl}/audience/${audienceId}?filter=${filterString}`),
		payload: {
			custom,
			errorMessage: ToastMessage.errorAudienceNotFound,
		},
	};
}

export const syncAudienceWithIntegrationTypes = createFetchTypes(
	`${mainType}_GET_SINGLE_AUDIENCE`,
);

function syncAudienceWithIntegration(
	custom = 'syncAudienceWithIntegration',
): fetchAction {
	return {
		type: syncAudienceWithIntegrationTypes,
		callAPI: () => axios.post(`${apiUrl}/audience/sync`),
		payload: {
			custom,
			successMessage: ToastMessage.audienceSyncing,
			errorMessage: ToastMessage.errorSyncingAudience,
		},
	};
}

export const createAudienceSegmentsTypes = createFetchTypes(
	`${mainType}_CREATE_AUDIENCE_SEGMENTS`,
);

export const createAudienceSegmentsTypesV2 = createFetchTypes(
	`${mainType}_CREATE_AUDIENCE_SEGMENTS_V2`,
);

function createAudienceSegments(
	audienceRules: AudienceRuleType[],
	custom = 'createAudienceSegments',
): fetchAction {
	return {
		type: createAudienceSegmentsTypes,
		callAPI: () => axios.post(`${apiUrl}/audienceRule/bulk`, { audienceRules }),
		payload: {
			custom,
		},
	};
}

function createAudienceSegmentsV2(
	audienceRules: AudienceRuleType[],
	custom = 'createAudienceSegmentsV2  ',
): fetchAction {
	return {
		type: createAudienceSegmentsTypesV2,
		callAPI: () =>
			axios.post(`${apiUrl}/audienceRule/v2/bulk`, { audienceRules }),
		payload: {
			custom,
		},
	};
}

export const editAudienceSegmentTypes = createFetchTypes(
	`${mainType}_EDIT_AUDIENCE_SEGMENT`,
);

export const editAudienceSegmentTypesV2 = createFetchTypes(
	`${mainType}_EDIT_AUDIENCE_SEGMENT_V2`,
);

export const verifyAudienceAddressTypes = createFetchTypes(
	`${mainType}_VERIFY_AUDIENCE_ADDRESS`,
);

function editAudienceSegment(
	audienceRule: AudienceRuleType,
	custom = 'editAudienceSegment',
): fetchAction {
	return {
		type: editAudienceSegmentTypes,
		callAPI: () => axios.put(`${apiUrl}/audienceRule`, audienceRule),
		payload: {
			custom,
			successMessage: ToastMessage.audienceSegmentUpdated,
			errorMessage: ToastMessage.error,
		},
	};
}

function editAudienceSegmentV2(
	audienceRule: AudienceRuleType,
	custom = 'editAudienceSegmentV2',
): fetchAction {
	return {
		type: editAudienceSegmentTypesV2,
		callAPI: () => axios.put(`${apiUrl}/audienceRule/v2`, audienceRule),
		payload: {
			custom,
			successMessage: ToastMessage.audienceSegmentUpdated,
			errorMessage: ToastMessage.error,
		},
	};
}

function verifyAudienceAddress(audienceId: string) {
	return {
		type: verifyAudienceAddressTypes,
		callAPI: () => axios.post(`${apiUrl}/audience/verifyAddress/${audienceId}`),
		payload: {
			custom: 'verifyAudienceAddress',
		},
	};
}
function resetAudienceState() {
	return {
		type: 'audience/RESET',
	};
}

export {
	saveAudience,
	saveAudienceV2,
	getAudience,
	updateAudience,
	deleteAudience,
	verifyAudienceAddress,
	getAudienceOptions,
	getSingleAudience,
	syncAudienceWithIntegration,
	createAudienceSegments,
	editAudienceSegment,
	resetAudienceState,
	createAudienceSegmentsV2,
	editAudienceSegmentV2,
};
