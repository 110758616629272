import React from 'react';
import { FloatingMenu } from '@common/design-system/components/molecules';
import { Badge } from '@common/design-system/components/atoms';
import { TableMapperType } from '../../types';
import { FloatingMenuPropsType } from '@common/design-system/components/molecules/FloatingMenu/FloatingMenu';

type PropsType = {
	item: any;
	mapperField: TableMapperType;
};

function BadgeFloatingMenuCell({ item, mapperField }: PropsType) {
	const badgeVariant =
		typeof mapperField.badgeVariant === 'function'
			? mapperField.badgeVariant(item)
			: mapperField.badgeVariant;

	const badgeText =
		typeof mapperField.badgeText === 'function'
			? mapperField.badgeText(item)
			: mapperField.badgeText;

	const menuItemsResult =
		typeof mapperField.menuItems === 'function'
			? mapperField.menuItems(item)
			: mapperField.menuItems;

	const menuItems = menuItemsResult?.map(
		(
			menuItem: FloatingMenuPropsType['menus'][number]['menuItems'][number],
		) => ({
			...menuItem,
			disabled:
				typeof menuItem.disabled === 'function'
					? menuItem.disabled(item)
					: menuItem.disabled,
		}),
	) as FloatingMenuPropsType['menus'][number]['menuItems'];

	return (
		<>
			{badgeVariant && badgeText && (
				<FloatingMenu
					menus={[{ menuItems }]}
					popUpPlacement="bottom-end"
					itemCallbackData={item}
					trigger={
						<Badge
							text={badgeText}
							dropdownBadge
							size={mapperField?.badgeSize}
							variant={badgeVariant}
						/>
					}
				/>
			)}
		</>
	);
}

export default BadgeFloatingMenuCell;
