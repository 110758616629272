import createFetchTypes from '@app/utils/createFetchTypes';
import { apiUrl } from '@app/constants/index';
import { fetchAction, GenericAPIResponse } from '../types';
import { FilterOptions } from '@app/types/index';
// import { EditStoryOptionsTypes, CreateStoryOptionsTypes } from "./types/index";
import axios from '@global/axios';
const mainType = 'sharedVideo';
type sharedVideo = {
	[key: string]: { [key: string]: any };
};

type ResponseType = GenericAPIResponse & {
	sharedVideos: sharedVideo[];
};

export const filterSharedVideoTypes = createFetchTypes(
	`${mainType}_FILTER_SHARED_VIDEOS`,
);

function filterSharedVideo(
	FilterOptions: FilterOptions,
	infinite = true,
): fetchAction {
	const filterString = JSON.stringify(FilterOptions);
	return {
		type: filterSharedVideoTypes,
		callAPI: () =>
			axios.get(
				`${apiUrl}/sharedVideo${
					FilterOptions ? `?filter=${filterString}` : ``
				}`,
			),
		payload: {
			infinite,
		},
	};
}

export { filterSharedVideo };
