import { useRef, useState } from 'react';

import {
	useFloating,
	flip,
	shift,
	hide,
	offset as offsetMiddleware,
	Placement,
	UseFloatingOptions,
	Strategy,
	useDismiss,
	useInteractions,
	autoUpdate,
	useClick,
	useRole,
	useFocus,
	useHover,
	OpenChangeReason,
	arrow,
} from '@floating-ui/react';

type PropsType = {
	preferredStrategy?: Strategy;
	preferredPlacement?: Placement;
	floatingConfig?: UseFloatingOptions;
	openOnFocus?: boolean;
	clickDisabled?: boolean;
	hoverDisabled?: boolean;
	closeOnOutsideClick?: boolean;
	onMenuOpen?: (event?: any, reason?: string) => void;
	onMenuClose?: (event?: any, reason?: string) => void;
	initialOpen?: boolean;
	offset?: number;
};

function useFloatingUi({
	preferredStrategy,
	preferredPlacement,
	floatingConfig,
	openOnFocus = false,
	clickDisabled = false,
	hoverDisabled = true,
	closeOnOutsideClick = true,
	onMenuOpen,
	onMenuClose,
	initialOpen = false,
	offset,
}: PropsType) {
	const [isOpen, setIsOpen] = useState(initialOpen);

	const arrowRef = useRef(null);

	const handleMenuToggle = (
		open: boolean,
		event?: Event,
		reason?: OpenChangeReason,
	) => {
		if (open) {
			setIsOpen(true);
			onMenuOpen && onMenuOpen(event, reason);
		} else {
			// If closeOnOutsideClick is false, then we should not close the menu on outside or escape key press
			if (
				!closeOnOutsideClick &&
				(reason === 'outside-press' || reason === 'escape-key')
			) {
				return;
			}

			setIsOpen(false);
			onMenuClose && onMenuClose(event, reason);
		}
	};

	const data = useFloating(
		floatingConfig || {
			open: isOpen,
			onOpenChange: handleMenuToggle,
			placement: preferredPlacement || 'right',
			middleware: [
				flip(),
				shift(),
				hide(),
				offsetMiddleware(offset || 8),
				arrow({
					element: arrowRef,
				}),
			],
			strategy: preferredStrategy || 'absolute',
			whileElementsMounted: autoUpdate,
		},
	);

	const context = data.context;

	const dismiss = useDismiss(context);
	const click = useClick(context, {
		enabled: !clickDisabled,
	});
	const role = useRole(context);
	const focus = useFocus(context, { enabled: openOnFocus, visibleOnly: false });

	const hover = useHover(context, {
		enabled: !hoverDisabled,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([
		click,
		dismiss,
		focus,
		role,
		hover,
	]);

	return {
		...data,
		x: data.x,
		y: data.y,
		isOpen: isOpen,
		setIsOpen,
		openingReference: data.refs.setReference,
		floatingReference: data.refs.setFloating,
		getReferenceProps,
		getFloatingProps,
		internalRefs: data.refs,
		strategy: data.strategy,
		middlewareData: data.middlewareData,
		arrowRef,
	};
}

export default useFloatingUi;
