import React from 'react';
import { TableMapperType } from '../../types';
import { Badge } from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function BadgeCell({ mapperField, item }: PropsType) {
	const badgeIconName =
		typeof mapperField.badgeIcon === 'function'
			? mapperField.badgeIcon(item)
			: mapperField.badgeIcon;

	const badgeVariant =
		typeof mapperField.badgeVariant === 'function'
			? mapperField.badgeVariant(item)
			: mapperField.badgeVariant;

	const badgeText =
		typeof mapperField.badgeText === 'function'
			? mapperField.badgeText(item)
			: mapperField.badgeText;

	const badgeIconWeight =
		typeof mapperField.badgeIconWeight === 'function'
			? mapperField.badgeIconWeight(item)
			: mapperField.badgeIconWeight;

	return (
		<Badge
			iconBadge={!badgeText}
			text={badgeText}
			size={mapperField.badgeSize}
			iconName={badgeIconName}
			variant={badgeVariant}
			iconWeight={badgeIconWeight}
			squared={mapperField.badgeSquared}
		/>
	);
}

export default BadgeCell;
