import { VideoType } from '@app/types/modules/video';

import logoPlaceholder from '@global/images/logo-placeholder.png';
import videoBackgroundPlaceholder from '@global/images/video-bg-placeholder.png';
import templateBackgroundPlaceholder from '@global/images/template-bg-placholder.png';
import avatarPreview from '@global/images/preview_avatar.png';

export enum VideoStatusEnum {
	preview = 'preview',
	play = 'play',
	end = 'end',
}

export const LOCAL_URL = 'http://localhost:8081';
export const VIDEO_PROD_PLACEHOLDER = 'https://educationdemo.goodkind.tv';
export const VIDEO_QA_PLACEHOLDER = 'https://vpage.goodkind-qa.com';
export const VIDEO_DEV_PLACEHOLDER = 'https://vpage.goodkind-dev.com';

export const videoUrls = {
	QA: `${VIDEO_QA_PLACEHOLDER}/edit/O7D1hCPy`,
	PROD: `${VIDEO_PROD_PLACEHOLDER}/edit/6p4gbGMs`,
	DEV: `${VIDEO_DEV_PLACEHOLDER}/edit/rRjy6Yyt`,
	LOCAL: `${LOCAL_URL}/edit/ki1vICTV`,
};

export const reelsVideoUrls = {
	QA: `${VIDEO_QA_PLACEHOLDER}/reels/edit/O7D1hCPy`,
	PROD: `${VIDEO_PROD_PLACEHOLDER}/reels/edit/6p4gbGMs`,
	DEV: `${VIDEO_DEV_PLACEHOLDER}/reels/edit/rRjy6Yyt`,
	LOCAL: `${LOCAL_URL}/reels/edit/ki1vICTV`,
};

export const PLACEHOLDER_CTA_OPTION = 'Select a Call to action*';

export const VIDEO_PLACEHOLDER_DATA: Partial<VideoType> = {
	moderation: 'OK',
	thumbnail:
		'https://givemagicstorage.blob.core.windows.net/assets/Thumbnails/FPyabBrRPaLKoKkk8anxMClnPPy5PQBS.jpg',
	mediaVersion: 3,
	hideCaptions: false,
	_id: '636aa3e8b55a4b002cdbe9e5',
	captions: [],
	transcript: [],
	status: 'StaticPlay',
	uploadedBy: {
		_id: '61f953c63278d40026ef7eb9',
		id: '61f953c63278d40026ef7eb9',
		firstName: 'admin',
		lastName: 'admin',
		fullName: 'admin',
	},
	createdAt: '2022-11-08T18:46:00.597Z',
	updatedAt: '2022-11-08T22:53:26.205Z',
	height: '700',
	width: '360',
	streamingUrl:
		'https://vi-streaming-gmmediaservice-usea.streaming.media.azure.net/b4dc3524-2d28-4da5-99dd-485ef73a1a83/FPyabBrRPaLKoKkk8anxMClnPPy5PQBS.ism/manifest',
	overlayedGIF:
		'https://givemagicstorage.blob.core.windows.net/assets/Thumbnails/ov-FPyabBrRPaLKoKkk8anxMClnPPy5PQBS-g.gif',
	videoGIF:
		'https://givemagicstorage.blob.core.windows.net/assets/Thumbnails/FPyabBrRPaLKoKkk8anxMClnPPy5PQBS-g.gif',
};

export const LOGO_BACKGROUND_PLACEHOLDER_IMAGES = {
	logo: logoPlaceholder,
	avatar: avatarPreview,
	videoBackground: videoBackgroundPlaceholder,
	templateBackground: templateBackgroundPlaceholder,
};

export const VIDEO_BACKGROUND_TOOLTIP_MESSAGE =
	"Uploaded image will replace the recorder's background in real-time while they're recording a video.";
