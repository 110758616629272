//using require here because VSCode doesnt implicitly hgrab custom type declaration files from the tsconfig.js
// this should be THE ONLY FILE with require delcarations
import playSrc from '@global/icons/play.svg';
import videoTooltipSrc from '@global/icons/video-tooltip.png';
// import * as playSrc from "../../../../../../global/icons/play.svg";

import blackBaudSrc from '@global/icons/blackbaud.png';
import salesForceSrc from '@global/icons/salesforce.png';
import hubspotSrc from '@global/icons/hubspot.png';
import zohoSrc from '@global/icons/zoho.png';
import sftpSrc from '@global/icons/sftp.png';
import blackbaudGreySrc from '@global/icons/crm-icons/blackbaud-icon-grey.svg';
import ellucianGreySrc from '@global/icons/crm-icons/ellucian-icon-grey.svg';
import salesforceGreySrc from '@global/icons/crm-icons/salesforce-icon-grey.svg';
import hubspotGreySrc from '@global/icons/crm-icons/hubspot-icon-grey.svg';
import iterableGreySrc from '@global/icons/crm-icons/iterable-icon-grey.svg';
import snowFlakeGreySrc from '@global/icons/crm-icons/snowflake-icon-grey.svg';
import dynamicsGreySrc from '@global/icons/crm-icons/dynamics-icon-grey.svg';
import slateGreySrc from '@global/icons/crm-icons/slate-icon-grey.svg';

import oneToOneSrc from '@global/icons/type-one-to-one.svg';
import oneToManySrc from '@global/icons/type-one-to-many.svg';
import collectSrc from '@global/icons/type-collect-videos.svg';
import websiteSrc from '@global/icons/type-website.svg';

import evenSrc from '@global/icons/even.svg';
import manualSrc from '@global/icons/manual.svg';
import openLinkSrc from '@global/icons/openlink.svg';
import minusSrc from '@global/icons/minus.svg';
import greenPlusSrc from '@global/icons/greenplus.svg';
import todoSrc from '@global/icons/todo.svg';
import backArrowSrc from '@global/icons/backarrow.svg';
import forwardArrowSrc from '@global/icons/forwardarrow.svg';
import whiteForwardArrowSrc from '@global/icons/forwardarrowWhite.svg';
import halfCircleArrowSrc from '@global/icons/halfCircleArrow.png';
import exSrc from '@global/icons/ex.svg';
import gm from '@global/icons/black-gm.png';
import chevronDownSrc from '@global/icons/chevrondown.svg';
import chevronDownAccentSrc from '@global/icons/chevrondownaccent.svg';
import text from '@global/icons/text.png';
import link from '@global/icons/link.png';
import code from '@global/icons/code.png';
import logo from '@global/icons/LOGO.svg';
// TODO: CTA - Rename these to be more generic when old ones are removed
import linkCTA from '@global/icons/link-cta.svg';
import dateCTA from '@global/icons/date-cta.svg';
import doubleLinkCTA from '@global/icons/doubleLink-cta.svg';
import codeCTA from '@global/icons/code-cta.svg';
// End TODO
import chevronLeftSrc from '@global/icons/chevronleft.svg';
import chevronRightSrc from '@global/icons/chevronright.svg';
import redoSrc from '@global/icons/redo.svg';
import minimizeSrc from '@global/icons/minimize.svg';
import maximizeSrc from '@global/icons/maximize.svg';
import basicSrc from '@global/icons/basics.png';
import integrationSrc from '@global/icons/integrations.png';
import backarrowAccentSrc from '@global/icons/backarrowAccent.svg';
import fowardarrowAccentSrc from '@global/icons/forwardarrowAccent.svg';
import filterSrc from '@global/icons/filter.svg';
import deleteSrc from '@global/icons/delete.svg';
import editWhiteSrc from '@global/icons/editWhite.png';
import editAccentSrc from '@global/icons/editAccent.svg';
import openlinkAccentSrc from '@global/icons/openlinkAccent.svg';
import deleteRedSrc from '@global/icons/deleteRed.svg';
import copyaccent from '@global/icons/copyaccent.svg';
import triangleAccent from '@global/icons/triangle.svg';
import triangleDark from '@global/icons/triangle-dark.svg';
import checkAccent from '@global/icons/check.svg';
import recordLinkLogo from '@global/icons/recordLinkLogo.png';
import uploadFile from '@global/icons/uploadFile.png';
import cartSrc from '@global/icons/cart.png';
import heartSrc from '@global/icons/heart.png';
import chatSrc from '@global/icons/chat.png';
import achievement from '@global/icons/achievement.png';
import exit from '@global/icons/exit.png';
import x from '@global/icons/x.png';
import star from '@global/icons/star.png';
import plus from '@global/icons/plus.png';
import orangePlus from '@global/icons/orangePlus.png';
import goodkindLogoSrc from '@global/images/goodkind-logo.svg';
import recordOne from '@global/icons/recordone.png';
import zapierSrc from '@global/icons/zapier.png';
import fbchatSrc from '@global/icons/fbchat.png';
import shopifySrc from '@global/icons/shopify.png';
import microsoftDynamicSrc from '@global/icons/microsoft_dynamics.png';
import recordTwo from '@global/icons/recordtwo.png';
import arrowUp from '@global/icons/arrowup.svg';
import teamLogo from '@global/icons/teamLogo.svg';
import resendLogo from '@global/icons/resendLogo.svg';
import emailLogo from '@global/icons/emailLogo.svg';
import smsLogo from '@global/icons/smsLogo.svg';
import integrationsLogo from '@global/icons/integrationsLogo.svg';
import organizationInfo from '@global/icons/organizationInfo.svg';
import pageEditor from '@global/icons/pageEditor.svg';
import termsLogo from '@global/icons/termsLogo.svg';
import billingLogo from '@global/icons/billingLogo.svg';
import infoWhite from '@global/icons/infowhite.png';
import placeholderCTA from '@global/icons/placeholder-cta.png';
import identityBadge from '@global/icons/identity_badge.png';
import adminBadge from '@global/icons/admin badge.png';
import mobileBadge from '@global/icons/mobile.png';
import draggable from '@global/icons/draggable.svg';
import defaultCampaign from '@global/icons/default-campaign.png';
import Warning from '@global/icons/warning.png';
import browserTop from '@global/icons/browser-top.png';
import wifiIcon from '@global/icons/wifi.svg';
import alert from '@global/icons/alert.svg';
import circleCheckGreen from '@global/icons/circle-check-green.svg';
import rightArrowOrange from '@global/icons/rightArrowOrange.png';
import starActive from '@global/icons/star-active.png';
import starNotActive from '@global/icons/star-not-active.png';
import deleteEx from '@global/icons/delete-ex.svg';
import roundedalert from '@global/icons/rounded-alert.svg';
import checked from '@global/icons/checked.png';
import blackstars from '@global/icons/blackstars.png';
import circleplus from '@global/icons/circleplus.svg';
import tagSrc from '@global/icons/tag.svg';
import blueCheckmark from '@global/icons/blue-checkmark.png';
import gkLogoSrc from '@global/images/goodkind-logo-icon.svg';
import customAttributes from '@global/icons/custom-attributes.svg';
import unsubscribes from '@global/icons/unsubscribes.svg';
import captionsOn from '@global/icons/captions-on.svg';
import captionsSrc from '@global/icons/captions.svg';
import speechBubble from '@global/icons/speech-bubble.png';
import smiley from '@global/images/smiley.svg';
import scriptExampleSrc from '@global/images/script-example.png';
import noVideosSrc from '@global/images/no-videos.svg';
import noItemsSrc from '@global/images/no-items.svg';
import heartbeatSrc from '@global/icons/heartbeat.svg';
import successSrc from '@global/icons/success.svg';
import campaignMonitorSrc from '@global/icons/campaign-monitor.svg';
import mmsUsSrc from '@global/icons/mms-us.svg';
import mobileCommonsSrc from '@global/icons/mobile-commons.svg';
import sendIconSrc from '@global/icons/sendicon.svg';
import globeSrc from '@global/icons/globe.svg';
import errorTriangle from '@global/icons/errorTriangle.svg';
import whatsAppLogo from '@global/icons/whatsapp-logo.svg';
import robot from '@global/icons/AI-robot-icon.svg';
import equalWhite from '@global/icons/equal-white.svg';
import notEqualWhite from '@global/icons/not-equal-white.svg';
import equalGrey from '@global/icons/equal-grey.svg';
import notEqualGrey from '@global/icons/not-equal-grey.svg';
import greaterThanGrey from '@global/icons/greater-than-grey.svg';
import greaterThanWhite from '@global/icons/greater-than-white.svg';
import lesserThanGrey from '@global/icons/lesser-than-grey.svg';
import lesserThanWhite from '@global/icons/lesser-than-white.svg';
import duplicate from '@global/icons/duplicate.svg';
import event from '@global/icons/event-icon.png';
import suggestedReply from '@global/icons/suggested-reply.png';
import eventCTA from '@global/icons/eventCTA.svg';
import aibotGrey from '@global/icons/ai-bot-grey.svg';
import aibot from '@global/icons/ai-bot.svg';
import aibotInactive from '@global/icons/ai-bot-inactive.svg';
import eventWhiteBg from '@global/icons/eventWhiteBg.svg';
import chatCircle from '@global/icons/chat-circle.svg';
import unarchivetray from '@global/icons/unarchivetray.svg';
import chatCircleTextMedium from '@global/icons/chat-circle-text-medium.svg';
import chatbotPlan from '@global/icons/chatbot-plan.png';
import chatbotPlanInactive from '@global/icons/chatbot-plan-inactive.png';
import inactiveIconLauncher from '@global/icons/inactive-icon-launcher.svg';
import activeIconLauncher from '@global/icons/active-icon-launcher.svg';
import inactiveMessagePromptLauncher from '@global/icons/inactive-message-prompt-launcher.svg';
import activeMessagePromptLauncher from '@global/icons/active-message-prompt-launcher.svg';
import inactiveSearchLauncher from '@global/icons/inactive-search-launcher.svg';
import activeSearchLauncher from '@global/icons/active-search-launcher.svg';
import inactiveChatLauncher from '@global/icons/inactive-chat-launcher.svg';
import activeChatLauncher from '@global/icons/active-chat-launcher.svg';
import inactiveNoLauncher from '@global/icons/inactive-no-launcher.svg';
import activeNoLauncher from '@global/icons/active-no-launcher.svg';
import mergeImages from '@global/icons/merge-images.svg';
import mergeImagesActive from '@global/icons/merge-images-active.svg';
import userCircleSlash from '@global/icons/user-circle-slash.svg';
import copilotSparkle from '@global/icons/copilot-sparkle.svg';
import videoMessageType from '@global/icons/video-messaging-type.svg';
import textMessageType from '@global/icons/text-messaging-type.svg';
import whatsAppType from '@global/icons/whatsApp-type.svg';
import reelsType from '@global/icons/reels-type.svg';
import postType from '@global/icons/post-type.svg';
import scanToWatch from '@global/icons/scan-to-watch.svg';
import videoMessageInactiveType from '@global/icons/video-messaging-inactive-type.svg';
import textMessageInactiveType from '@global/icons/text-messaging-inactive-type.svg';
import reelsInactiveType from '@global/icons/reels-inactive-type.svg';
import xFile from '@global/icons/x-file.svg';
import starFile from '@global/icons/star-file.svg';

export const IconEnum: { [key: string]: string } = {
	inactiveiconlauncher: inactiveIconLauncher,
	activeiconlauncher: activeIconLauncher,
	inactivemessagepromptlauncher: inactiveMessagePromptLauncher,
	activemessagepromptlauncher: activeMessagePromptLauncher,
	inactivesearchlauncher: inactiveSearchLauncher,
	activesearchlauncher: activeSearchLauncher,
	inactivechatlauncher: inactiveChatLauncher,
	activechatlauncher: activeChatLauncher,
	inactivenolauncher: inactiveNoLauncher,
	activenolauncher: activeNoLauncher,
	videomessagingplan: videoMessageType,
	videomessagingplaninactive: videoMessageInactiveType,
	videomessagetype: videoMessageType,
	textmessagetype: textMessageType,
	whatsapptype: whatsAppType,
	reelstype: reelsType,
	posttype: postType,
	scantowatch: scanToWatch,
	chatbotplan: chatbotPlan,
	chatbotplaninactive: chatbotPlanInactive,
	reelsplan: reelsType,
	reelsplaninactive: reelsInactiveType,
	smsplan: textMessageType,
	smsplaninactive: textMessageInactiveType,
	unarchivetray: unarchivetray,
	campaignmonitor: campaignMonitorSrc,
	mmsus: mmsUsSrc,
	mobilecommons: mobileCommonsSrc,
	zapier: zapierSrc,
	fbchat: fbchatSrc,
	shopify: shopifySrc,
	microsoftdynamics: microsoftDynamicSrc,
	play: playSrc,
	videotooltip: videoTooltipSrc,
	blackbaud: blackBaudSrc,
	salesforce: salesForceSrc,
	hubspot: hubspotSrc,
	sftp: sftpSrc,
	zoho: zohoSrc,
	blackbaudgrey: blackbaudGreySrc,
	salesforcegrey: salesforceGreySrc,
	hubspotgrey: hubspotGreySrc,
	elluciangrey: ellucianGreySrc,
	iterablegrey: iterableGreySrc,
	snowflakegrey: snowFlakeGreySrc,
	dynamicsgrey: dynamicsGreySrc,
	slategrey: slateGreySrc,
	onetoone: oneToOneSrc,
	onetomany: oneToManySrc,
	collect: collectSrc,
	website: websiteSrc,
	draggable: draggable,
	redo: redoSrc,
	openlink: openLinkSrc,
	minus: minusSrc,
	greenplus: greenPlusSrc,
	todo: todoSrc,
	backarrow: backArrowSrc,
	forwardarrow: forwardArrowSrc,
	forwardarrowwhite: whiteForwardArrowSrc,
	halfcirclearrow: halfCircleArrowSrc,
	ex: exSrc,
	gm: gm,
	chevrondown: chevronDownSrc,
	chevrondownaccent: chevronDownAccentSrc,
	text: text,
	link: link,
	logo: logo,
	// TODO: remove this when old icons are removed
	linkcta: linkCTA,
	codecta: codeCTA,
	datecta: dateCTA,
	doublelinkcta: doubleLinkCTA,
	// End TODO
	chevronleft: chevronLeftSrc,
	chevronright: chevronRightSrc,
	minimize: minimizeSrc,
	maximize: maximizeSrc,
	basic: basicSrc,
	integration: integrationSrc,
	code: code,
	backarrowaccent: backarrowAccentSrc,
	fowardarrowaccent: fowardarrowAccentSrc,
	filter: filterSrc,
	delete: deleteSrc,
	editwhite: editWhiteSrc,
	editaccent: editAccentSrc,
	openlinkaccent: openlinkAccentSrc,
	deletered: deleteRedSrc,
	copyaccent: copyaccent,
	triangleaccent: triangleAccent,
	triangledark: triangleDark,
	checkaccent: checkAccent,
	recordlink: recordLinkLogo,
	uploadfile: uploadFile,
	cart: cartSrc,
	heart: heartSrc,
	chat: chatSrc,
	achievement: achievement,
	exit: exit,
	x: x,
	star: star,
	xfile: xFile,
	starfile: starFile,
	orangeplus: orangePlus,
	plus: plus,
	goodkindlogo: goodkindLogoSrc,
	recordone: recordOne,
	recordtwo: recordTwo,
	arrowup: arrowUp,
	teamlogo: teamLogo,
	resendlogo: resendLogo,
	emaillogo: emailLogo,
	smslogo: smsLogo,
	integrationslogo: integrationsLogo,
	organizationinfo: organizationInfo,
	pageeditor: pageEditor,
	termslogo: termsLogo,
	billinglogo: billingLogo,
	infowhite: infoWhite,
	placeholdercta: placeholderCTA,
	identitybadge: identityBadge,
	adminbadge: adminBadge,
	mobilebadge: mobileBadge,
	warning: Warning,
	browsertop: browserTop,
	defaultcampaign: defaultCampaign,
	wifi: wifiIcon,
	alert,
	circlecheckgreen: circleCheckGreen,
	rightarroworange: rightArrowOrange,
	staractive: starActive,
	starnotactive: starNotActive,
	roundedalert,
	deleteex: deleteEx,
	checked,
	blackstars,
	circleplus,
	tag: tagSrc,
	bluecheckmark: blueCheckmark,
	gklogo: gkLogoSrc,
	customattributes: customAttributes,
	unsubscribes,
	captionson: captionsOn,
	captions: captionsSrc,
	speechbubble: speechBubble,
	smiley,
	scriptexample: scriptExampleSrc,
	novideos: noVideosSrc,
	noitems: noItemsSrc,
	heartbeat: heartbeatSrc,
	even: evenSrc,
	manual: manualSrc,
	success: successSrc,
	sendicon: sendIconSrc,
	globe: globeSrc,
	errortriangle: errorTriangle,
	whatsapplogo: whatsAppLogo,
	robot,
	aibotgrey: aibotGrey,
	aibot,
	aibotinactive: aibotInactive,
	equalwhite: equalWhite,
	equalgrey: equalGrey,
	notequalwhite: notEqualWhite,
	notequalgrey: notEqualGrey,
	greaterthangrey: greaterThanGrey,
	greaterthanwhite: greaterThanWhite,
	lesserthangrey: lesserThanGrey,
	lesserthanwhite: lesserThanWhite,
	duplicate,
	event,
	suggestedreplycta: suggestedReply,
	eventcta: eventCTA,
	eventctawhitebg: eventWhiteBg,
	chatcircle: chatCircle,
	chatcircletextmedium: chatCircleTextMedium,
	mergeimages: mergeImages,
	mergeimagesactive: mergeImagesActive,
	usercircleslash: userCircleSlash,
	copilotsparkle: copilotSparkle,
};
