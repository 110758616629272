//TODO: REFACTOR THIS GUY TO ANOTHER NAME, IS NOT ONLY FOR USER DISPLAY ANYMORE

import React from 'react';
import { TableMapperType } from '../../types';
import styled from 'styled-components';
import {
	Avatar,
	Text,
	Badge,
	Icon,
	Tooltip,
	DisplayIcon,
} from '@common/design-system/components/atoms';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

const Wrapper = styled.div<{ onClick?: any }>`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.medium};
	cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

	.texts-wrapper {
		display: flex;
		flex-direction: column;
		gap: ${({ theme }) => theme.spacing.xxs};

		.title-wrapper {
			position: relative;
			width: max-content;
		}
	}
`;

const ThumbnailWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.shapeRadius.xxs};

	img {
		width: 100%;
	}
`;

function UserDisplayCell({ mapperField, item }: PropsType) {
	const textMaxLength = mapperField?.textMaxLength || 50;
	const badgeText =
		typeof mapperField.userBadge === 'function'
			? mapperField.userBadge(item)
			: mapperField.userBadge;

	const userIconName =
		typeof mapperField.userIcon === 'function'
			? mapperField.userIcon(item)
			: mapperField.userIcon;

	const userIconTooltipText =
		typeof mapperField.userIconTooltipText === 'function'
			? mapperField.userIconTooltipText(item)
			: mapperField.userIconTooltipText;

	const isDisabled =
		typeof mapperField.isDisabled === 'function'
			? mapperField.isDisabled(item)
			: mapperField.isDisabled;

	const userDisplayIconProps =
		typeof mapperField?.userDisplayIconProps === 'function'
			? mapperField?.userDisplayIconProps(item)
			: mapperField?.userDisplayIconProps;

	const useThumbnail =
		typeof mapperField?.useUserThumbnail === 'function'
			? mapperField?.useUserThumbnail(item)
			: !!mapperField?.useUserThumbnail;

	const titleFontWeight =
		typeof mapperField?.userTitleFontWeight === 'function'
			? mapperField?.userTitleFontWeight(item)
			: mapperField?.userTitleFontWeight || 'medium';

	const subTitleFontWeight =
		typeof mapperField?.userSubTitleFontWeight === 'function'
			? mapperField?.userSubTitleFontWeight(item)
			: mapperField?.userSubTitleFontWeight || 'regular';

	const userTitleItalic =
		typeof mapperField?.userTitleItalic === 'function'
			? mapperField?.userTitleItalic(item)
			: mapperField?.userTitleItalic || false;

	const userSubTitleItalic =
		typeof mapperField?.userSubTitleItalic === 'function'
			? mapperField?.userSubTitleItalic(item)
			: mapperField?.userSubTitleItalic || false;
	const truncateText = (text: string) => {
		if (text && text.length > textMaxLength) {
			return `${text.substring(0, textMaxLength).trim()}...`;
		}
		return text;
	};
	return (
		<>
			<Wrapper
				onClick={
					!!mapperField?.onUserClick
						? () => mapperField?.onUserClick && mapperField?.onUserClick(item)
						: undefined
				}
			>
				{useThumbnail && !userDisplayIconProps && (
					<ThumbnailWrapper>
						<img
							src={item[mapperField?.userPictureKey || 'profilePicture']}
							alt={item.name}
						/>
					</ThumbnailWrapper>
				)}

				{!useThumbnail && !userDisplayIconProps && (
					<Avatar image={item.profilePicture} name={item.name} />
				)}

				{userDisplayIconProps && !useThumbnail && (
					<DisplayIcon {...userDisplayIconProps} />
				)}

				<div className="texts-wrapper">
					<div className="title-wrapper">
						<Text
							size="small"
							fontWeight={titleFontWeight}
							color={isDisabled ? 'system.text.medium' : 'system.text.default'}
							isItalic={userTitleItalic}
						>
							{truncateText(item[mapperField?.userTitleKey || 'name'])}
						</Text>
					</div>
					<div className="title-wrapper">
						<Text
							size="small"
							color="system.text.medium"
							fontWeight={subTitleFontWeight}
							isItalic={userSubTitleItalic}
						>
							{truncateText(item[mapperField?.userSubTitleKey || 'email'])}
						</Text>
					</div>
				</div>

				{mapperField?.userBadge && !!badgeText && (
					<Badge text={badgeText} size="small" variant="warning" />
				)}

				{mapperField?.userIcon && !!userIconName && (
					<Tooltip content={userIconTooltipText || ''}>
						<span>
							<Icon
								iconName={userIconName}
								color="system.text.weak"
								weight="fill"
							/>
						</span>
					</Tooltip>
				)}
			</Wrapper>
		</>
	);
}

export default UserDisplayCell;
