import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import { normalize } from 'normalizr';
import { filterSharedVideoTypes } from './action';
import entityNormalizer from '../../utils/entityNormalizer';

const sharedVideo = entityNormalizer(
	'sharedVideos',
	{},
	{ idAttribute: '_id' },
);
const pagination = { sharedVideos: [sharedVideo] };

// add pagination shouldCallAPI only when the filterObject string is equals to the currentFilter (store currentfilter on each api call )
const initialState: Omit<GenericEntityState, 'custom'> = {
	loading: false,
	sharedVideos: {},
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
};

export type sharedVideosStateType = typeof initialState;

function paginationMapper(
	state: sharedVideosStateType,
	action: ResponseAction,
) {
	const {
		response: {
			sharedVideos,
			page = null,
			pageSize = null,
			totalPages = null,
			count = null,
		},
	} = action;

	const { infinite } = action.payload;

	const normalizedData = normalize(
		{ sharedVideos, page, pageSize, totalPages, count },
		pagination,
	);

	if (infinite) {
		state.sharedVideos = {
			...state.sharedVideos,
			...normalizedData.entities.sharedVideos,
		};
	}

	if (!infinite) {
		state.sharedVideos = {
			...normalizedData.entities.sharedVideos,
		};
	}

	state.pagination.pages[action.response.page] =
		normalizedData.result.sharedVideos;
	state.pagination.currentPage = action.response.page;
	state.pagination.totalPages = action.response.totalPages;
	state.pagination.count = action.response.count;
}

const sharedVideoSlice = createSlice({
	name: 'sharedVideo',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(filterSharedVideoTypes, paginationMapper),
	},
});

export default sharedVideoSlice.reducer;
