import GoodkindDSTheme from '@common/design-system/global/theme';
import styled from 'styled-components';

const getColor = (
	variant: 'primary' | 'system' | 'neutral',
	theme: typeof GoodkindDSTheme,
	active?: boolean,
	disabled?: boolean,
) => {
	if (active) {
		return theme.colors.primary.text.strong;
	}

	if (disabled) {
		return theme.colors.system.text.weak;
	}

	switch (variant) {
		case 'primary':
			return theme.colors.primary.text.strong;
		case 'system':
			return theme.colors.system.text.default;
		case 'neutral':
			return theme.colors.system.text.medium;
		default:
			return theme.colors.system.text.default;
	}
};

export const TabButtonStyled = styled.button<{
	active?: boolean;
	disabled?: boolean;
	smallPadding?: boolean;
	variant: 'primary' | 'system' | 'neutral';
}>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 6px;
	padding: 0
		${({ theme, smallPadding }) =>
			smallPadding ? theme.spacing.small : theme.spacing.medium};
	height: 32px;
	border-radius: ${({ theme }) => theme.shapeRadius.small};
	transition: ${({ theme }) => theme.transitions.easeInOut15};
	border: none;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

	color: ${({ active, disabled, variant, theme }) =>
		getColor(variant, theme, active, disabled)};

	background: ${({ active, theme }) =>
		active
			? theme.colors.primary.background.default
			: theme.colors.system.background.xWeak};

	&:hover {
		background: ${({ active, theme }) =>
			active
				? theme.colors.primary.background.defaultActive
				: theme.colors.system.background.weak};
	}
`;
