import { env } from '@app/env';

export enum operators {
	greaterThan = 'gt',
	greaterThanEquals = 'gte',
	lesserThan = 'lt',
	lesserThanEquals = 'lte',
	not = 'not',
	exists = 'exists',
	in = 'in',
	notIn = 'nin',
}

export enum orderTypes {
	ascending = 'asc',
	descending = 'desc',
}
export const sortDropdownOptions = [
	{
		key: 'engagementRate',
		text: 'Most engaging',
		value: 'engagementRate',
	},
	{
		key: 'storyViews',
		text: 'Most views',
		value: 'storyViews',
	},
	{
		key: 'totalTransactedSum',
		text: 'Most donations',
		value: 'totalTransactedSum',
	},
];

export const APP_VERSION = PACKAGE_APP_VERSION;

export const apiUrl = env.API_URL;

export const cubeURL = env.CUBE_API_URL;

export const webRecordingUrl = env.WEB_RECORDING_URL;

export const growthbookApiKey = env.GROWTHBOOK_API_KEY;

export const segmentWriteKey = env.SEGMENT_WRITE_KEY;

function getVideoPageUrlDomain() {
	switch (env.NODE_ENV) {
		case 'stage':
			return 'vpage.goodkind-stage.com';

		case 'dev':
			return 'vpage.goodkind-dev.com';
		case 'qa':
			return 'vpage.goodkind-qa.com';

		case 'production':
		case 'prod':
			return 'goodkind.tv';

		default:
			return 'vpage.goodkind-qa.com';
	}
}

export const videoPageUrlDomain = getVideoPageUrlDomain();
export const storytellerAlgoliaId = env.ALGOLIA_STORYTELLER_INDEX_NAME;

export const videosAlgoliaId = env.ALGOLIA_VIDEO_INDEX_NAME;

export const hotglueApiKey = env.HOTGLUE_API_KEY;

export const hotglueEnvId = env.HOTGLUE_ENV_URL;

export enum ToastMessage {
	success = 'Success',
	error = 'There was an error, can you please try again',
	loading = 'Loading...',
	errorUpdatingWorkspace = 'There has been an error updating the workspace, please try again',
	errorAuthenticatingBlackbaud = 'You’ll need to authenticate with Blackbaud in order to send a Blackbaud donation',
	smsOff = 'One of the contacts has SMS preference, please turn SMS messaging on in Settings before sending video',
	smsSent = 'SMS sent',
	updateWorkspace = 'You’ve successfully updated your Workspace!',
	copied = 'Copied!',
	failedToCopy = 'Failed to copy!',
	archive = 'Successfully archived',
	numbersNotAllowed = 'Numbers not allowed',
	specialCharactersNotAllowed = 'Special characters are not allowed',
	overApprovedContactLimit = 'Campaign addition limit reached, please approve pending contacts.',
	assignContactsInboxCampaignError = 'Unable to add contacts to the inbox campaign due to invalid contacts.',

	/** Video Folders*/

	addVideoToFolder = 'Video added to folder',
	deleteVideo = 'Video deleted successfully',
	createFolder = 'Folder created',
	folderExist = 'A folder with this name already exists',
	removeVideoFromFolder = 'Video removed from folder',
	editFolderSuccess = 'Folder updated successfully',

	/**CTA*/
	createCTA = 'You have successfully created a new action',
	updateCTA = 'You have successfully updated an action',
	errorUpdatingCTA = 'There has been an error updating the action, please try again',
	errorCreatingCTA = 'There has been an error creating the new action, can you please try again',
	deleteFolder = 'Folder deleted',
	downloadVideo = 'Check your email for the download link',
	ctaNotSelected = 'There is no CTA selected',

	/**Delete modal*/
	deletedAssignStory = 'You’ve deleted an assigned video',

	/**Edited assigned story*/
	editedAssignedStory = 'You’ve edited an assigned video',

	/**Campaign wizard*/
	campaignUpdated = 'Your campaign has been updated',
	createdCampaign = 'Campaign created',
	savedDraftCampaign = 'Campaign saved as draft',
	errorCreatingCampaign = 'There has been an error creating campaign, please try again',
	errorSendingAddress = 'Workspace requires default sending address to be verified in order to launch campaigns',
	campaignLaunched = 'Campaign successfully launched',
	audienceCreated = 'Audience successfully created',
	audienceUpdated = 'Audience successfully updated',
	audienceDeleted = 'Audience successfully deleted',
	audienceSelected = 'Audience successfully selected',
	errorUpdatingAudience = 'There has been an error updating the audience, please try again',
	errorCreatingAudience = 'There has been an error creating audience, please try again',
	errorAudienceNotFound = 'Audience not found',
	scriptSaved = 'Script saved',
	campaignNameUpdated = 'Campaign name has been updated',
	campaignUpdatedNameDuplicated = 'Campaign updated. Warning: Campaign name already exists',
	campaignNameDuplicatedWarning = 'Warning: Campaign name already exists',
	ctaCreatedAndSelected = 'Action created and selected',
	deactivatedCampaign = 'Campaign deactivated successfully',
	draftCampaignsDeleted = 'Draft campaign deleted successfully',
	campaignPinned = 'Campaign pinned successfully',
	campaignUnpinned = 'Campaign unpinned successfully',
	videoIsPendingApproval = 'Selected video is pending approval',
	campaignDueDateUpdated = 'Due date saved',
	campaignDiscardDueDate = 'Due date discarded',
	emailTemplateUpdated = 'Email template successfully updated',
	addContactsToCampaignError = 'Contacts approval error',
	fallbackCampaignMissing = 'Fallback campaign missing',
	templateNotAssignedToTeamError = 'Can not launch campaign, the user template is not assigned to the team selected',
	audienceNotVerifiedError = 'The selected audience has not been verified. Verify the audience before launching the campaign.',
	/** Audience*/
	audienceMaxContactError = 'Too many contacts. Please contact your customer success manager to increase your limit',
	audienceSyncing = 'Audience syncing started',
	errorSyncingAudience = 'There has been an error syncing audience',
	audienceSegmentUpdated = 'Audience segment successfully updated',
	duplicateSegmentsName = 'One or more segment names already exist',

	/** Campaign summary*/
	campaignPreviewSent = 'Campaign preview sent successfully',
	postcardPreviewSent = 'Postcard preview sent successfully',
	errorSendingPreview = 'There was an error sending the email preview',
	invalidPhoneNumber = 'Invalid phone number(s). Please correct and try again.',

	/**Triggers*/
	errorFillLastTrigger = 'Fill the last trigger',

	/**Resend stories*/
	storyResent = 'Campaign videos successfully resent',
	errorResendingStories = 'There has been an error resending videos.',

	/** Import contacts*/
	uploadContacts = 'Contacts successfully imported',

	/** Contacts */
	deletedContacts = 'Contacts deleted',
	assignedStoryCreated = 'Assigned video created',
	errorContactSendingAddress = 'Workspace requires default sending address to be verified in order to create a video message',
	contactAlreadyExistInCampaign = 'Contact already exists in campaign',
	unableToAddContact = 'Unable to add contact to this type of campaign.',
	errorContactNotFound = 'Contact not found',
	createdContact = 'Contact successfully created.',
	contactWithEmailAlreadyExists = 'A contact already exists with the same email.',

	/*** Video */
	videoSent = 'Video sent',
	sendingVideo = 'Sending video...',
	videoUploaded = 'Video successfully uploaded',
	captionsUpdated = 'Captions updated',
	favorite = 'Marked as favorite',
	favoriteUpdated = 'Favorite updated',
	thumbnailUpdated = 'Thumbnail updated',
	videoUpdated = 'Video successfully updated',
	errorUpdatingVideo = 'There has been an error updating the video, please try again',

	/** Story */
	storyCreated = 'Video created successfully',
	errorCreatingStory = 'Error creating video',
	storyEnRoute = 'Your video is now en route',
	errorAssigningStory = 'Error assigning video',
	storySent = 'Video sent successfully',
	errorSendingStory = 'Error sending video',
	errorScheduleSendingAlreadySendStory = 'Campaign contacts already have shared video',

	/**Auth*/
	errorPermissionDenied = 'Permission denied. You do not have administrator access.',
	loggedIn = 'You have now successfully logged in.',
	errorLoginFirst = 'You must log in first to be able to use the platform!',
	resetPassword = 'Password reset email sent',
	userUpdated = 'User profile updated',
	errorUpdatingUser = 'Error updating user profile',

	/**Storyteller*/
	storytellerUpdated = 'Team member updated successfully',
	errorCreatingStoryteller = 'Error inviting team member',
	storytellerCreated = 'Team member has been invited successfully',
	errorEditingStoryteller = 'There has been an error editing the team member',
	storytellerDeleted = 'Team members deleted successfully',
	notificationPreferencesUpdated = 'Notification preferences updated',
	errorUpdatingNotificationPreferences = 'Error updating notification preferences',

	/** Recorders */
	recordersInstructionsUpdated = 'Instructions saved',

	/** Onboarding */
	errorWorkspaceAlreadyCreated = 'Workspace already created',
	colorsUpdated = 'Colors updated',
	errorAddValidaStoryteller = 'Please add a valid recorder',
	onBoardingComplete = 'Congratulations, you’re now ready to use Goodkind.',

	/** Blackbaud*/
	alreadyAuthenticated = 'Already authenticated',
	errorSelectBBMS = 'Please select BBMS account ID',

	/** Integration*/
	integrationConnected = 'Integration connected',
	integrationUpdated = 'Integration updated',
	integrationDisconnected = 'Integration disconnected',

	/** Workspace*/
	workspaceUpdated = 'Workspace updated',
	workspaceCreated = 'Workspace created!',
	workspaceIdCopied = 'Workspace ID copied!',
	errorWorkspaceEmail = 'Workspace requires default sending address to be verified in order to create a video.',
	errorEmailAddressWorkspaceSelection = 'Incorrect email address. Does not match team member on record.',
	profilePictureUploaded = 'Successfully uploaded profile picture',
	customAttributeCreated = 'Custom attribute successfully created',
	errorCreatingCustomAttribute = "There's been an error creating the custom attribute",
	duplicatedCustomAttribute = 'Custom attribute with the same name already exists',
	customAttributeMapped = 'Custom attribute succesfully mapped',
	domainVerified = 'Email address domain successfully verified',
	errorDomainAuthentication = 'Email address domain not authenticated',
	errorDomainNotFound = 'Email address domain not found',
	customAttributePinned = 'Custom attribute pin updated',
	workspaceInvitationSent = 'Invitation(s) to workspace sent',
	trustScoreDocAdded = 'Trust score document added',
	trustScoreDocDeleted = 'Trust score document deleted',
	trustScoreDocUpdated = 'Trust score document updated',
	errorAddingTrustScoreDoc = 'Error adding trust score document',
	errorDeletingTrustScoreDoc = 'Error deleting trust score document',
	errorUpdatingTrustScoreDoc = 'Error updating trust score document',
	mmsThumbnailPreviewSent = 'Preview sent',
	errorSendingMmsThumbnailPreview = 'Error sending preview',
	mailingAddressMapped = 'Mailing address mapped',
	errorMappingMailingAddress = 'Error mapping mailing address',

	/** CardStorytellers email verification */
	resendVerificationSuccess = 'Verification successfully resent',
	verificationProgress = 'Recorder verification in progress',
	verificationSuccess = 'Recorder verification is successful',

	/** Upload*/
	errorVideoUpload = 'Error uploading, the video can not be more than 100MB',
	contactsToCampaignAdded = 'All valid contacts were added to the campaign',
	contactsToCampaignError = 'You have selected one or more contacts that are already part of this campaign.',
	updatedSettings = 'Settings updated ',

	/** Tags */
	tagUpdated = 'Tag successfully updated',
	tagDeleted = 'Tag successfully deleted',
	tagsUpdated = 'Tags successfully updated',
	tagsCreated = 'Tags successfully created',
	tagsDeleted = 'Tags successfully deleted',

	/** Campaign Story Templates */
	storyTemplateUpdated = 'Template successfully updated',
	storyTemplateCreated = 'Template successfully created',
	storyTemplateCreateError = 'Error creating template, please try again',
	storyTemplateUpdateError = 'Error updating template, please try again',

	/** Story/Reply details */
	errorStoryNotFound = 'Video not found',
	errorReplyNotFound = 'Reply not found',
	updatedReply = 'Successfully updated reply',
	updatedReplies = 'Successfully updated replies',
	singleReplyStoryDeleted = 'Reply deleted',
	severalReplyStoriesDeleted = 'Replies deleted',
	singleScheduledStoryDeleted = 'Scheduled video deleted',
	severalScheduledStoriesDeleted = 'Scheduled videos deleted',
	singleApprovalStoryDeleted = 'Video deleted',
	severalApprovalStoriesDeleted = 'Videos deleted',
	singleAssignedStoryDeleted = 'Assigned video deleted',
	severalAssignedStoriesDeleted = 'Assigned video deleted',
	singleStoryArchived = 'Sent video archived',
	severalStoriesArchived = 'Sent videos archived',
	singleStoryUnarchived = 'Sent video unarchived',
	severalStoriesUnarchived = 'Sent videos unarchived',

	/** Approvals */
	storyRejected = 'Rejected successfully',

	/** Template **/
	duplicatedTemplateNameError = 'Name of template already exists. Try another name',
	manualDistributionError = 'Please check your assignment percentage',
	backgroundRequired = 'Background image is missing in the template',
	logoRequired = 'Logo is missing in the template',
	backgroundAndLogoRequired = 'Background image and logo are missing in the template',

	/** Captions Settings */
	threeWordsNotAllowed = 'Keywords can only have maximum of 2 words',

	/** Automations */
	automationNotFound = 'Automation not found',
	createAutomation = "You've succesfully created your automation",
	editAutomation = "You've successfully edited your automation",

	/** Groups */
	groupNotFound = 'Group not found',
	createGroup = "You've succesfully created your group",
	groupWithoutStorytellers = 'Every group needs at least one recorder for assignment.',

	// Caption suggestion
	suggestionCreated = 'Suggestion created',
	suggestionDeleted = 'Suggestion removed',

	// Caption override
	overrideCreated = 'Override created',
	overrideDeleted = 'Override removed',
	overrideCreatedError = 'Workspace caption override already exists',
	/** Delivery Channels */
	deliveryChannelUpdated = 'Delivery Channel updated successfully',

	/** Unique Identifiers */
	uniqueIdUpdateSuccess = 'Unique Identifier successfully updated.',
	uniqueIdUpdateFailure = 'Unique Identifier update failed.',

	// Settings page
	errorUpdatingSettings = 'Error updating Settings, please try again',
	videoPageSettingsUpdated = 'Video page settings updated',

	//Teams
	teamCreateSuccess = 'Team created successfully',
	teamCreateError = 'Error during team creation, please try again',
	teamCreateErrorDuplicatedName = 'Team with name already exists',
	teamDeleteSuccess = 'Team deleted successfully',
	teamDeleteError = 'Error during team deletion, please try again',
	teamUpdateSuccess = 'Team updated successfully',
	teamUpdateError = 'Error during team update, please try again',
	storytellerTeamsUpdateSuccess = 'Team member updated successfully',
	storytellerTeamsUpdateError = 'Error during team member update, please try again',
	storytellerTeamsDeleteSuccess = 'Team member deleted successfully',
	storytellerTeamsDeleteError = 'Error during team member deletion, please try again',

	// Inbox/Conversations
	archivedConversation = 'Conversation archived',
	unarchivedConversation = 'Conversation unarchived',
	messagesSent = 'Message(s) added to sending queue',
	previewMessageSent = 'Preview message(s) added to sending queue',
	messagesSentError = 'Error sending messages, please try again',
	optOutSuccess = 'Contact opted out',
	phoneNumberCopySuccess = 'Phone number copied to clipboard',
	emailCopySuccess = 'Email copied to clipboard',
	contactsAreInvalid = 'No contacts with valid phone numbers in audience.',

	//Chatbot
	updateAiBotSuccess = 'Chatbot successfully updated',
	updateAiBotFailed = 'Chatbot update failed, please try again',
	createAiDocSuccess = 'AiDoc successfully created',
	createAiDocFailed = 'AiDoc creation failed, please try again',
	pdfUploadCouldNotFindText = 'Could not find text in the uploaded PDF',
	pdfProcessingFailed = 'AiDoc creation failed, could not process the PDF',
	deleteAiDocSuccess = 'AiDoc deleted successfully',
	deleteAiDocFailed = 'AiDoc deletion failed, please try again',
	updateAiDocSuccess = 'AiDoc updated successfully',
	updateAiDocFailed = 'AiDoc update failed, please try again',
	updateAiMessageSuccess = 'AiMessage successfully updated',
	updateAiMessageFailed = 'AiMessage update failed, please try again',
	approvedAnswer = 'Question and answer approved',
	questionUpdate = 'Question successfully updated',
	questionCreated = 'Question successfully added',
	createAiBotSuccess = 'Chatbot successfully created',
	createAiBotFailed = 'Chatbot creation failed, please try again',
	updateTopicSuccess = 'Topic successfully updated',
	updateTopicFailed = 'Topic update failed, please try again',
	dismissedAnswerSuggestion = 'Answer suggestion dismissed',
	dismissedAnswerSuggestions = 'Answer suggestions dismissed',
	answerUpdated = 'Answer successfully updated',
	answersUpdated = 'Answers successfully updated',
	removeUnansweredSuccess = 'Unanswered question removed successfully',
	removeUnansweredFailed = 'Unanswered question removal failed, please try again',

	//settingsV2
	organizationUpdateSuccess = 'Organization successfully updated',
	organizationUpdateFailed = 'Organization update failed, please try again',
	ChangesSaved = 'Changes saved',
	KeywordAlreadyAdded = 'Keyword already added',
	dataExportJobTriggered = 'Data export job triggered successfully',
	selectInboxNotificationScheduleError = 'Please select a schedule for inbox notifications',

	// User templates
	templateCreateSuccess = 'Template created successfully',
	templateUpdateSuccess = 'Template updated successfully',
	templateCreateError = 'Error during template creation, please try again',
	templateUpdateError = 'Error during template update, please try again',
	templateDeleteSuccess = 'Template deleted successfully',
	templateDeleteError = 'Error during template deletion, please try again',
	templateAssignSuccess = 'Team(s) assigned successfully',
	templateAssignError = 'Error during team assignment, please try again',
	templateDuplicateSuccess = 'Template duplicated successfully',
	templateDuplicateError = 'Error during template duplication, please try again',
	whatsappTemplateCreateSuccess = "Your template has been added to 'Received' and is now awaiting WhatsApp's approval",

	//activity
	removeActivity = 'Your messages have been cancelled',
	sendNowActivity = 'Your messages have been promoted. Messages are now on route',

	// Data import
	contactAttributeCreated = 'Contact attribute created successfully',
	contactAttributeUpdated = 'Contact attribute updated successfully',
}

export enum PageToastMessage {
	// Automatic Resend Off
	automaticResendText = 'Automatic resending is currently off. We recommend turning it on.',
	automaticResendToolTip = 'Goodkind will resend video messages to contacts who do not open the video page at the specific time interval. Turning on resending will work for new sends and does not retroactively apply.',

	// Outside Sending Window
	sendingWindowText = 'Your campaign will be scheduled to be sent during the next opening of your sending window. To send now, please schedule your campaign to override.',
	sendingWindowToolTip = 'Adjust the sending window in settings to be able to send the video',
	sendingWindowLinkText = 'View sending window',
	sendingWindowTextWithScheuledDate = 'Your workspace is outside of its sending window. Your campaign will be sent on the selected scheduled date and time.',
	// SMS Off
	SMSOffText = 'Your campaign has contacts with SMS as their sending method, but text messaging is off on your workspace.',
	SMSOffToolTip = 'Turn on text messaging',

	// Assignment Group
	assignmentGroupToolTip = 'Assignment groups are based on custom attributes and segment your assignments based on your own logic in addition to a standard Goodkind assignment rule',

	// Campaign Creation Stepper
	stepperMissingNameToolTipText = 'Name your campaign before going to the next step',

	// Contacts
	importContactsToolTip = 'Import contacts into audience',
	newContactAlertLimitTrigger = 'New contact alert limit triggered ',
	newContactAlertModalBody = 'All new contacts will receive the video message that is part of this campaign during your sending window. ',
	newContactAlertDeactivateButton = 'Deactivate campaign',
	newContactAlertAcceptButton = 'Add',
	newContactsAlertCancelAction = 'Cancel',
}

export const ToastPosition = {
	top: '100px',
	right: '36px',
	width: 'auto',
};

export const GoodKindApp = {
	Android: 'https://goodkind.app',
	IOS: 'https://goodkind.app',
};

export enum ServiceResponse {
	saveDraft = 'draft saved',
	videoArchived = 'video archived',
	favoriteUpdated = 'favorite updated',
	toggledCaptions = 'Captions toggled',
}

export enum SegemntEvents {
	viewedPage = 'Viewed Page',
	login = 'Login',
}

export const engagementScoreHint =
	'Engagement rate takes into account the % of the video watched, hearting, shares & more.';

export const pinCustomAttributeTooltip =
	'Mark an attribute for your team to easily view contact information in the mobile application';

export const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ValidationMessages = {
	empty_field: 'Cannot be an empty field',
	email_required: 'E-mail is required',
	email_invalid: 'E-mail is invalid',
	name_required: 'Name is required',
	name_invalid: 'Name is invalid',
	first_name_required: 'First name is required',
	first_name_invalid: 'First name is invalid',
	last_name_required: 'Last name is required',
	last_name_invalid: 'Last name is invalid',
	phone_number_required: 'Phone number is required',
	phoneNumber_invalid: 'Phone number is invalid',
};

export enum TableManagerIdentifierEnum {
	AUDIENCE_INTERACTIONS = 'audienceInteractionsTableManagerState',
	AUDIENCE_CONTACTS = 'audienceContactsTableManagerState',
}

export const hoursPerDay = 24;

export enum TrustScoreEnum {
	PENDING = 'pending',
	NEUTRAL = 'neutral',
	GOOD = 'good',
	NEEDS_WORK = 'needs-work',
	BAD = 'bad',
}

export enum AuthConnectionEnum {
	'auth0' = 'auth0',
	'google' = 'google-oauth2',
	'apple' = 'apple',
}

export enum AuthConnectionNameEnum {
	'auth0' = 'Auth0',
	'google' = 'Google',
	'apple' = 'Apple',
}

export const goodkindHQEmail = '@goodkindhq.com';
export const goodkindOnlyEmail = '@goodkind.com';
export const weAreGoodkindEmail = '@wearegoodkind.com';
