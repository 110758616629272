import { VideoPageSettingsType } from '../../types';
import { TeamType } from '@app/types/modules/teams';

export enum CampaignStateEnum {
	active = 'active',
	inactive = 'inactive',
	draft = 'draft',
}

export type campaignStatusType =
	| CampaignStateEnum.active
	| CampaignStateEnum.inactive
	| CampaignStateEnum.draft;

export enum SyncStatus {
	READY = 'ready',
	INACTIVE = 'inactive',
	IMPORTING = 'importing',
	ACTIVE = 'active',
}

export type StatsType = {
	playPercentage: number;
	engagementRate: number;
	numberOfPlays: number;
	uniquePlays: number;
	storyViews: number;
	uniqueStoryViews: number;
	shares: number;
	shareViews: number;
	shareSignUps: number;
	totalTransactedSum: number;
	numberOfLinkClicks: number;
	storiesSent: number;
};

export type CampaignSettingsType = VideoPageSettingsType;

export type ContactsByPreferenceType = {
	both: number;
	email: number;
	sms: number;
};

export type CampaignOverlayType = {
	_id: string;
	name: string;
	snapGroupdId: string;
	snapLensId: string;
	[key: string]: any;
};

export type CampaignType = {
	audienceHandlingType: string;
	value: any;
	hideReply?: boolean;
	tags: any[];
	contacts: any[];
	contactUniqueIdentifier: string;
	team?: string | TeamType;
	storytellers: any[];
	completedStories: any[];
	campaignFallback?: boolean;
	contactsByPreference: ContactsByPreferenceType;
	stats: StatsType;
	callToActions: any;
	pinned?: boolean;
	audience: any;
	state: campaignStatusType;
	creationMethod: string;
	expirationMethod: string;
	personalization: string;
	reelsTitle?: string;
	pendingStories: any[];
	assignedStories: any[];
	storiesPendingApproval: any[];
	videosRequiringApproval: number;
	storiesCount: number;
	instructions?: string;
	dueDate?: string;
	noInsights: number;
	waitForProcessing: number;
	strictApproval: number | boolean;
	heartCount: number;
	sendThroughDefault: boolean;
	childCampaigns: any[];
	isChild: boolean;
	smsSent: boolean;
	unsubscribes: number;
	bounces: number;
	emailPreferenceCount: number;
	SMSPreferenceCount: number;
	bothPreferenceCount: number;
	isComplete: boolean;
	scheduledStories: any[];
	selectedContactLists: any[];
	scheduledByWindow: boolean;
	_id: string;
	name: string;
	location: string;
	scheduleDate: string | Date;
	smsBody?: string;
	emailSubjectTitle?: string;
	recipe?: any;
	workspace: string;
	lastUpdatedBy: string;
	createdBy: {
		_id: string;
		id: string;
		fullName: string;
		firstName: string;
		lastName: string;
	};
	triggers: any[];
	createdAt: string | Date;
	updatedAt: string | Date;
	currentChild: null | any[];
	currentStep: string;
	filter: null | string;
	video: any;
	template: null | any;
	launchedAt: string | Date;
	emailsSent: number;
	lastStorySentAt: string;
	lastStorySentUrl: string;
	emailOpens: number;
	engagementCount: number;
	lastStoryResentAt: string;
	contactCount: number;
	assignedStoriesCount: number;
	completedStoriesCount: number;
	pendingStoriesCount: number;
	storiesPendingApprovalCount: number;
	id: string;
	formattedUpdatedAt: string;
	formattedCreatedAt: string;
	recordingScript: string;
	autoplay: boolean;
	settings?: CampaignSettingsType;
	syncStatus?: SyncStatus;
	lastSyncDate?: string | Date;
	subscriberListId?: string;
	emailTemplateId?: string;
	assignmentType?: string;
	overlay?: CampaignOverlayType;
	widgetDomain?: string;
	widgetPath?: string;
	whatsappTemplateId?: string;
	videoViewer?: string;
	lastImportJob?: any;
	whatsappEnabled?: boolean;
	replyToName?: string;
	replyToAddress?: string;
	emailSenderName?: string;
	emailSendingAddress?: string;
	message?: {
		campaign?: string;
		content: {
			text: string;
			id: string;
			medias: {
				status: string;
				type: string;
				url: string;
				_id: string;
			}[];
		};
		whatsappTemplateId?: string;
	};
	automationData?: {
		active?: boolean;
		campaignId?: string;
		targetAssignmentHours?: number;
	};
	groups?: any;
	automation?: any; //TODO: Check on this, is accessing on Setup step init set
	scheduledMessages?: { campaign: string; id: string; _id: string }[];
	deliveryChannel?: 'all' | 'email' | 'sms' | null;
	lastStoryWithVideo?: any;
	userTemplateId?: string;
};
