import { Button } from '@common/design-system/components/atoms';
import React from 'react';
import { TableMapperType } from '../../types';
import { FloatingMenu } from '@common/design-system/components/molecules';

type PropsType = {
	item: any;
	mapperField: TableMapperType;
};

function IconButtonCell({ item, mapperField }: PropsType) {
	const menuItems =
		typeof mapperField.menuItems === 'function'
			? mapperField.menuItems(item)
			: mapperField.menuItems;

	if (!menuItems) {
		return (
			<Button
				variant="outlined"
				iconButton
				disabled={mapperField?.buttonProps?.disabled}
				size="medium"
				icon={mapperField?.iconName || 'cube'}
				onClick={() => {
					if (mapperField?.onIconButtonClick) {
						mapperField?.onIconButtonClick(item);
					}
				}}
			/>
		);
	}

	return (
		<FloatingMenu
			menus={menuItems ? [{ menuItems }] : []}
			closeOnSelect
			trigger={
				<Button
					variant="outlined"
					iconButton
					disabled={mapperField?.buttonProps?.disabled}
					size="medium"
					icon={mapperField?.iconName || 'cube'}
					onClick={() => {
						if (mapperField?.onIconButtonClick) {
							mapperField?.onIconButtonClick(item);
						}
					}}
				/>
			}
			itemCallbackData={item}
		/>
	);
}

export default IconButtonCell;
