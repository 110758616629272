import { createSlice } from '@reduxjs/toolkit';
import createFetchReducer from '@app/utils/createFetchReducer';
import { GenericEntityState, ResponseAction } from '@app/redux/types';

import {
	getContactAttributesTypes,
	getAttributeValuesTypes,
	createContactAttributeTypes,
	editContactAttributeTypes,
	bulkUpdateContactAttributesTypes,
} from './action';

const initialState: GenericEntityState = {
	loading: false,
	contactAttributes: [],
	pagination: {
		pages: {},
		currentPage: null,
		totalPages: null,
	},
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {
		createContactAttributeLoading: false,
		editContactAttributeLoading: false,
	},
};

export type contactAttributesType = typeof initialState;

function getContactAttributesMapper(
	state: contactAttributesType,
	action: ResponseAction,
) {
	state.contactAttributes = action.response.contactAttributes;
}

function upsertContactAttributesMapper(
	state: contactAttributesType,
	action: ResponseAction,
) {
	state.contactAttributes = [action.response.contactAttribute];
}

const contactAttributesSlice = createSlice({
	name: 'contactAttributes',
	initialState,
	reducers: {},
	extraReducers: {
		...createFetchReducer(
			getContactAttributesTypes,
			getContactAttributesMapper,
		),
		...createFetchReducer(getAttributeValuesTypes),
		...createFetchReducer(
			createContactAttributeTypes,
			upsertContactAttributesMapper,
		),
		...createFetchReducer(
			editContactAttributeTypes,
			upsertContactAttributesMapper,
		),
		...createFetchReducer(bulkUpdateContactAttributesTypes),
	},
});

export default contactAttributesSlice.reducer;
