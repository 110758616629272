import styled from 'styled-components';

export const BulletStepperContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing.small};
`;

export const BulletStepperItem = styled.div<{
	active?: boolean;
	disabled?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 8px;
	height: 8px;
	border-radius: ${({ theme }) => theme.shapeRadius[50]};
	background-color: ${({ theme, active, disabled }) =>
		disabled
			? theme.colors.system.background.xWeak
			: active
				? theme.colors.primary.text.hover
				: theme.colors.primary.background.default};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};

	:hover {
		background-color: ${({ theme, active, disabled }) =>
			disabled
				? theme.colors.system.text.weak
				: active
					? theme.colors.primary.text.hover
					: theme.colors.primary.text.hover};
	}
`;
