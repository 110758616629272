import React from 'react';
import { TableMapperType } from '../../types';
import { Badge, Icon, StatusIndicator, Text, Tooltip } from '../../../../atoms';
import styled from 'styled-components';
import { Popover } from '@common/design-system/components/molecules';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

const CellContainer = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing.xs};
`;

const TextsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};
`;

function TextCell({ mapperField, item }: PropsType) {
	//Text fields
	const text = item[mapperField?.key];

	const subText = mapperField?.subTextKey
		? typeof mapperField?.subTextKey === 'function'
			? mapperField?.subTextKey(item)
			: item[mapperField?.subTextKey]
		: undefined;

	const descriptionText = mapperField?.descriptionTextKey
		? typeof mapperField.descriptionTextKey === 'function'
			? (mapperField.descriptionTextKey as (item: any) => string | undefined)(
					item,
				)
			: (item[mapperField.descriptionTextKey] as string | undefined)
		: undefined;

	//Text length settings
	const textMaxLength = mapperField?.textMaxLength || 25;

	const textLength = text?.length;
	const subTextLength = subText?.length;
	const descriptionTextLength = descriptionText?.length ?? 0;

	const isTruncated =
		textLength > textMaxLength ||
		subTextLength > textMaxLength ||
		descriptionTextLength > textMaxLength;

	//Text status
	const textStatusIndicatorVariant =
		typeof mapperField.textStatus === 'function'
			? mapperField.textStatus(item)
			: mapperField.textStatus;

	const hasStatus = !!textStatusIndicatorVariant;

	const statusOnCell = mapperField?.textStatusOnCell ?? hasStatus;
	const statusOnText = mapperField?.textStatusOnText ?? hasStatus;
	const statusOnSubText = mapperField?.textStatusOnSubText ?? hasStatus;
	const statusOnDescription = mapperField?.textStatusOnDescription ?? hasStatus;

	//Text icon
	const iconText =
		typeof mapperField.textIcon === 'function'
			? mapperField.textIcon(item)
			: mapperField.textIcon;

	const hasIcon = !!iconText;

	const iconOnCell =
		typeof mapperField.textIconOnCell === 'function'
			? mapperField.textIconOnCell(item)
			: mapperField?.textIconOnCell ?? hasIcon;
	const iconOnText = mapperField?.textIconOnText ?? hasIcon;
	const iconOnSubText =
		typeof mapperField?.textIconOnSubText === 'function'
			? mapperField.textIconOnSubText(item)
			: mapperField?.textIconOnSubText ?? hasIcon;
	const iconOnDescription =
		typeof mapperField?.textIconOnDescription === 'function'
			? mapperField.textIconOnDescription(item)
			: mapperField?.textIconOnDescription ?? hasIcon;

	const textTooltipText =
		typeof mapperField.textTooltipText === 'function'
			? mapperField.textTooltipText(item)
			: mapperField.textTooltipText;

	const textPopoverContent =
		typeof mapperField?.textPopoverContent === 'function'
			? mapperField?.textPopoverContent(item)
			: mapperField?.textPopoverContent;

	const subTextBadgeData =
		mapperField?.textSubTextBadge &&
		(typeof mapperField.textSubTextBadge === 'function'
			? mapperField.textSubTextBadge(item)
			: mapperField.textSubTextBadge);

	const subTextBadgeVariant =
		typeof subTextBadgeData?.variant === 'function'
			? subTextBadgeData?.variant(item)
			: subTextBadgeData?.variant;

	const subTextBadgeText =
		typeof subTextBadgeData?.text === 'function'
			? subTextBadgeData?.text(item)
			: subTextBadgeData?.text;

	//Truncate text in textMaxLength characters maximum, for some reason Text component noWrap and line clamp doesn't work inside the table cells
	const truncateText = (text: string) => {
		if (text && text.length > textMaxLength) {
			return `${text.substring(0, textMaxLength).trim()}...`;
		}
		return text;
	};

	//Get tooltip text
	const getTooltipText = () => {
		if (isTruncated && !textTooltipText) {
			const tooltipText =
				text +
				(subText ? `\n\n ${subText}` : '') +
				(descriptionText ? `\n\n ${descriptionText}` : '');

			return tooltipText;
		}

		return textTooltipText || '';
	};

	const Cell = React.forwardRef(function Cell(
		_props,
		ref: React.Ref<HTMLDivElement>,
	) {
		return (
			<CellContainer ref={ref}>
				{hasIcon && iconOnCell && (
					<Icon
						iconName={iconText as string}
						color="system.text.weak"
						weight="fill"
					/>
				)}

				{hasStatus && statusOnCell && (
					<StatusIndicator variant={textStatusIndicatorVariant} />
				)}
				<TextsContainer>
					<span className="d-flex align-items-center gap-2">
						{hasStatus && statusOnText && (
							<StatusIndicator variant={textStatusIndicatorVariant} />
						)}

						{hasIcon && iconOnText && (
							<Icon
								iconName={iconText as string}
								color="system.text.weak"
								weight="fill"
							/>
						)}

						<Text
							size="small"
							noWrap
							lineHeight="medium"
							fontWeight={mapperField?.textTitleFontWeight || 'regular'}
						>
							{truncateText(text)}
						</Text>
					</span>

					{!!subText && (
						<span className="d-flex align-items-center gap-2">
							{hasStatus && statusOnSubText && (
								<StatusIndicator variant={textStatusIndicatorVariant} />
							)}
							{hasIcon && iconOnSubText && (
								<Icon
									iconName={iconText as string}
									color="system.text.weak"
									weight="fill"
								/>
							)}
							<Text
								size="small"
								noWrap
								color="system.text.medium"
								lineHeight="medium"
								fontWeight={mapperField.bold ? 'bold' : 'regular'}
							>
								{truncateText(subText)}
							</Text>
							{subTextBadgeData && (
								<Badge
									variant={subTextBadgeVariant}
									text={subTextBadgeText}
									size="small"
								/>
							)}
						</span>
					)}

					{!!descriptionText && (
						<span className="d-flex align-items-center gap-2">
							{hasStatus && statusOnDescription && (
								<StatusIndicator variant={textStatusIndicatorVariant} />
							)}

							{hasIcon && iconOnDescription && (
								<Icon
									iconName={iconText as string}
									color="system.text.weak"
									weight="fill"
								/>
							)}

							<Text
								size="xs"
								noWrap
								color="system.text.medium"
								lineHeight="medium"
								fontWeight={mapperField.bold ? 'bold' : 'regular'}
							>
								{truncateText(descriptionText)}
							</Text>
						</span>
					)}
				</TextsContainer>
			</CellContainer>
		);
	});

	if (textPopoverContent) {
		return (
			<Popover openOnHover withArrow popOverContent={textPopoverContent}>
				<Cell />
			</Popover>
		);
	}

	return (
		<Tooltip content={getTooltipText()}>
			<Cell />
		</Tooltip>
	);
}

export default React.memo(TextCell);
