import React, { useMemo } from 'react';
import NavLinkButton, {
	NavLinkButtonProps,
} from '../NavLinkButton/NavLinkButton';

import * as S from './NavLinkWithSubMenu.styles';

export type NavLinkWithSubMenuProps = {
	navLink: NavLinkButtonProps;
	isSubMenuOpen: boolean;
	isNavbarCollapsed?: boolean;
	loading: boolean;
	capitalizeTitle?: boolean;
	showMenuCount?: boolean;
};
function NavLinkWithSubMenu({
	navLink,
	isSubMenuOpen,
	isNavbarCollapsed,
	loading,
	capitalizeTitle = true,
	showMenuCount = false,
}: NavLinkWithSubMenuProps) {
	const title = useMemo(() => {
		let title = navLink.text;

		if (showMenuCount) {
			title = `${title} (${navLink?.menus?.length})`;
		}

		if (capitalizeTitle) {
			title = title.toUpperCase();
		}
		return title;
	}, [navLink.text, capitalizeTitle, showMenuCount]);
	return (
		<S.NavLinkWithSubMenu key={navLink.text}>
			{/* Hide the parent accordion button if the navbar is collapsed or the title is empty*/}
			{!!title && !isNavbarCollapsed && (
				<NavLinkButton
					key={`nav-link-${navLink.text}`}
					{...navLink}
					text={title}
				/>
			)}

			{(isNavbarCollapsed || isSubMenuOpen) && (
				<S.SubMenuWrapper
					isOpen={isSubMenuOpen}
					isNavbarCollapsed={isNavbarCollapsed}
					isSubMenusThreaded={navLink?.isSubMenusThreaded}
				>
					{navLink?.menus?.map((menu) => {
						return (
							<NavLinkButton
								key={`nav-sublink-${navLink.text}-${menu.text}`}
								text={menu.text}
								iconName={menu.iconName}
								inactiveIconName={menu.inactiveIconName}
								count={menu.count}
								redirectTo={menu.redirectTo}
								onClick={menu.onClick}
								isDisabled={loading}
								active={menu.active}
								textBadge={menu.textBadge}
								isNavbarCollapsed={isNavbarCollapsed}
							/>
						);
					})}
				</S.SubMenuWrapper>
			)}
		</S.NavLinkWithSubMenu>
	);
}

export default NavLinkWithSubMenu;
