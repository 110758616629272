import React, { ReactNode } from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';

export type DragDropContainerProps = {
	children: ReactNode;
	droppableId: string;
	className?: string;
	onDragEnd: (result: DropResult) => void;
	onDragStart?: (e: any) => void;
};

export default function DragDropContainer({
	children,
	droppableId,
	className,
	onDragEnd,
	onDragStart,
}: DragDropContainerProps) {
	const getListStyle = (_isDraggingOver: boolean) => ({
		display: 'flex',
		'flex-direction': 'column',
	});

	/** This component will wrap the DraggableContainer list */
	return (
		<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
			<Droppable droppableId={droppableId}>
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						className={className}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{children}

						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}
