import { createSlice } from '@reduxjs/toolkit';
import { GenericEntityState, ResponseAction } from '../types';
import createFetchReducer from '../../utils/createFetchReducer';
import {
	grantAccessTypes,
	resetPasswordTypes,
	updateAuthUserTypes,
	emailCheckTypes,
} from './action';
import TokenStorage from '../../services/Auth/TokenStorage';
import authConfig from '../../services/Auth/authConfig';
import { showToast } from '@common/design-system/components/molecules';
import { connectionsNameMapper } from './constants';
import { ToastMessage } from '@app/constants';
import LocalStorage from '@app/utils/LocalStorage';

const initialState: Omit<GenericEntityState, 'pagination'> = {
	loading: true,
	authProviderInfo: {
		user: null,
		logout: () => {},
		loginWithRedirect: () => {},
	},
	user: null,
	error: {},
	response: {
		status: null,
		message: null,
	},
	custom: {},
};

export type AuthState = typeof initialState;

function resetPasswordMapper(state: AuthState, action: ResponseAction) {
	const { response } = action;
}

function resetPasswordErrorMapper(state: AuthState, action: ResponseAction) {
	const { response } = action?.error;
	const errorCode = response?.data?.errorCode;

	const connections: string[] = response?.data?.metadata?.connections;

	const isUserMissing =
		errorCode === 38 ||
		response?.data?.message?.toLowerCase().includes('user not found');
	const isUsingIdentityProvider = errorCode === 701;

	if (connections?.length > 0 && isUsingIdentityProvider) {
		const connectionName = connectionsNameMapper[connections[0]];

		return showToast({
			message: `Please use your identity provider (${connectionName}) to reset your password`,
			type: 'error',
		});
	}

	if (isUserMissing) {
		return showToast({
			message: `User not found.`,
			type: 'error',
		});
	}

	showToast({
		message: ToastMessage.error,
		type: 'error',
	});
}

function grantAccessMapper(state: AuthState, action: ResponseAction) {
	const {
		response: { identityToken, storyteller },
	} = action;
	TokenStorage.setIdentity(identityToken);
	state.user = storyteller;
	if (action.payload?.reload && !!identityToken) {
		window.location.reload();
	}
}

function updateAuthUserMapper(state: AuthState, action: ResponseAction) {
	const { storyteller } = action.response;

	state.user = storyteller;
}

function emailCheckSuccessMapper(state: AuthState, action: ResponseAction) {
	const { authProvider } = action.response;

	LocalStorage.setItemInLocalStorage('checkAuthProvider', authProvider);
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		SET_AUTH_PROVIDER_INFO(state: AuthState, action) {
			state.authProviderInfo = action.payload.provider;
		},
		CLEAR_AUTH(state: AuthState, action) {
			try {
				LocalStorage.clear();
				state?.authProviderInfo?.logout({ returnTo: authConfig.logoutUrl });
			} catch (e) {}
		},
	},
	extraReducers: {
		...createFetchReducer(grantAccessTypes, grantAccessMapper),
		...createFetchReducer(updateAuthUserTypes, updateAuthUserMapper),
		...createFetchReducer(
			resetPasswordTypes,
			resetPasswordMapper,
			resetPasswordErrorMapper,
		),
		...createFetchReducer(emailCheckTypes, emailCheckSuccessMapper),
	},
});

export default authSlice.reducer;

export const { CLEAR_AUTH } = authSlice.actions;
