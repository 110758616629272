import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
`;

export const ScreenBackground = styled.div`
	position: absolute;
	height: 100%;
	width: 100%;
	background: ${({ theme }) => `${theme.colors.neutral.background.default}`};
	opacity: 0.85;
`;

export const ScreenCover = styled.div`
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	/* height: 100%; */
	min-height: 100vh;
	width: 100%;
	z-index: ${({ theme }) => theme.zIndex.modalBackdrop};
	animation: ${fadeIn} 0.15s linear normal;
`;

export const PaneContainer = styled.div<{ containerWidth?: string }>`
	position: absolute;
	right: 0;
	display: flex;
	flex-direction: column;
	background: ${({ theme }) => theme.colors.system.background.system};
	box-shadow: ${({ theme }) => theme.shadows.elevation.medium};
	z-index: ${({ theme }) => theme.zIndex.modal};
	width: ${({ containerWidth }) => containerWidth};
	height: 100%;
`;

export const PaneContent = styled.div``;

export const PaneHeader = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: ${({ theme }) => theme.spacing.medium};
`;

export const PaneFooter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	column-gap: ${({ theme }) => theme.spacing.medium};
	border-top: 1px solid ${({ theme }) => theme.colors.neutral.border.default};
	padding: ${({ theme }) => theme.spacing.large};
`;
