import styled, { css } from 'styled-components';

export const SubMenuWrapper = styled.div<{
	children: any;
	isOpen?: boolean;
	isNavbarCollapsed?: boolean;
	isSubMenusThreaded?: boolean;
}>`
	width: auto;
	max-height: ${({ isOpen, isNavbarCollapsed }) =>
		isOpen || isNavbarCollapsed ? '100%' : '0'};
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing.xxs};

	${({ isSubMenusThreaded }) => {
		if (isSubMenusThreaded) {
			return css`
				padding-left: ${({ theme }) => theme.spacing.xs};
				margin-left: ${({ theme }) => theme.spacing.medium};
				border-left: 1px solid ${({ theme }) => theme.colors.system.border.weak};
			`;
		}
	}}
`;

export const NavLinkWithSubMenu = styled.div``;
