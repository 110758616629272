import React from 'react';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { getImageSize, getCrop } from 'polotno/utils/image';
import { SectionTab } from 'polotno/side-panel';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { t } from 'polotno/utils/l10n';
import { Icon, Input, Text } from '@common/design-system/components/atoms';
import { StoreType } from 'polotno/model/store';
import { parseTextWithLinks } from '@app/utils/parseTextWithLinks';

import * as S from './PhotosSection.styles';
import { ImageElementType } from 'polotno/model/image-model';
import { env } from '@app/env';

const sourceName = 'Unsplash';
const sourceUrl = 'https://unsplash.com/';
const API_KEY = env.UNSPLASH_ACCESS_KEY; // TODO: Replace with GK Access Key
const API_URL = 'https://api.unsplash.com/search/photos';

type getPhotosProps = {
	query: string;
	page: number;
};
const getPhotos = ({ query, page }: getPhotosProps) => {
	return `${API_URL}/?query=${query}&per_page=20&page=${page}&client_id=${API_KEY}`;
};

export const PhotosPanel = ({ store }: { store: StoreType }) => {
	const { setQuery, loadMore, isReachingEnd, data, isLoading, error } =
		useInfiniteAPI({
			defaultQuery: 'university',
			getAPI: ({ page, query }) => getPhotos({ page, query }),
			getSize: (lastResponse) =>
				lastResponse.total_results / lastResponse.per_page,
		});

	const handleImageSelect = async (
		src: string,
		pos: any,
		element: ImageElementType,
	) => {
		// if we dropped image into svg element, le't apply mask for it
		if (element && element.type === 'svg' && element.contentEditable) {
			element.set({ maskSrc: src });
			return;
		}

		// get image size
		let { width, height } = await getImageSize(src);
		const aspectRatio = width / height;

		// Scale down the image to fit the canvas
		width = 500;
		height = width / aspectRatio;

		// if we dropped into another image, let's just recalucate crop and apply new image
		if (element && element.type === 'image' && element.contentEditable) {
			const crop = getCrop(element, {
				width,
				height,
			});
			element.set({ src, ...crop });
			return;
		}

		// otherwise let's create new image
		const x = (pos?.x || store.width / 2) - width / 2;
		const y = (pos?.y || store.height / 2) - height / 2;
		store.activePage?.addElement({
			type: 'image',
			src,
			width,
			height,
			x,
			y,
		});
	};

	return (
		<S.Container>
			<Input
				search
				size="small"
				placeholder={t('sidePanel.searchPlaceholder')}
				onChange={(e: any) => {
					setQuery(e.target.value);
				}}
			/>
			<Text size="small" className="my-2">
				{parseTextWithLinks({
					text: `Photos by [${sourceName}](${sourceUrl})`,
					fontSize: 'medium',
				})}
			</Text>

			<ImagesGrid
				images={data?.map((d) => d.results).flat() || []}
				getPreview={(image) => image.urls.thumb}
				onSelect={async (image, pos, element) =>
					handleImageSelect(image.urls.full, pos, element)
				}
				isLoading={isLoading}
				error={error}
				loadMore={!isReachingEnd && loadMore}
				getCredit={(image) => {
					const designerName = image.user?.name || 'Unknown';
					const designerUrl = image.user?.links?.html || '#';
					return (
						<Text size="xs">
							{parseTextWithLinks({
								text: `Photos by [${designerName}](${designerUrl}) on [${sourceName}](${sourceUrl})`,
								fontSize: 'small',
							})}
						</Text>
					);
				}}
			/>
		</S.Container>
	);
};

// define the new custom section
const PhotosSection = {
	name: 'photos',
	Tab: (props: any) => (
		<SectionTab name="Photos" {...props}>
			<Icon
				iconName="image"
				size="large"
				weight="fill"
				color={props?.active ? 'primary.text.hover' : 'system.icon.default'}
			/>
		</SectionTab>
	),
	// we need observer to update component automatically on any store changes
	Panel: PhotosPanel,
};
export default PhotosSection;
