import React from 'react';
import * as S from './MultiBadgeCell.styles';
import { TableMapperType } from '../../types';
import { Badge } from '@common/design-system/components/atoms';
import { BadgePropsType } from '@common/design-system/components/atoms/Badge/Badge';
import { Popover } from '@common/design-system/components/molecules';

type PropsType = {
	mapperField: TableMapperType;
	item: any;
};

function MultiBadgeCell({ mapperField, item }: PropsType) {
	const badgeMapperArray: BadgePropsType[] =
		typeof mapperField.badgeArray === 'function'
			? mapperField.badgeArray(item)
			: mapperField.badgeArray ?? [];

	const cellPopoverContent =
		typeof mapperField.cellPopoverContent === 'function'
			? mapperField.cellPopoverContent(item)
			: mapperField.cellPopoverContent;

	const Cell = React.memo(function Cell() {
		return (
			<S.ArrayWrapper>
				{badgeMapperArray.map((badge, index) => (
					<Badge key={index} {...badge} />
				))}
			</S.ArrayWrapper>
		);
	});

	if (cellPopoverContent) {
		return (
			<Popover openOnHover withArrow popOverContent={cellPopoverContent}>
				<Cell />
			</Popover>
		);
	}

	return <Cell />;
}

export default MultiBadgeCell;
